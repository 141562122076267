import React, { useReducer } from "react";
import reactCSS from 'reactcss'
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import  NuInput from "components/nubox/NuInput";

import { SketchPicker } from 'react-color'

import ImageUpload from "components/nubox/ImageUpload";
import confirmService from 'components/confirmService';


import { withRouterJim } from "router/withRouterJim";

//--- CONTEXT API -----------
import { useStore, useDispatch, StoreContext } from "store/StoreProvider";
import {types, storeReducer} from "store/StoreReducer"

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
]


//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Producto extends React.Component {
  state = {

    modalImagen: false,
    displayColorPicker: false,
    //id_prod, nombre_p, descrip, preciov1, color
    form: {
      idd:0,
      ope:'n',  
      id_prod: 0,
      nombre_p:'',
      descrip:'',
      preciov1:0,
      color:'',
    },
    
    
  };
  //---- context API
  static contextType  = StoreContext;

  

  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

       // Get the userId param from the URL.
    let idd = this.props.params.idd;
    let ope = this.props.params.ope;
    
    console.log("idd-->" + idd);
    console.log("ope-->" + ope);
    this.setState({ form:{...this.state.form, idd: idd, ope:ope}}); 
    if(ope==='e' || ope==='x'){
      this.cargar_producto(idd, ope);

    }
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      //console.log("cheched:"+ e.target.checked);
      let res = e.target.checked? 1:0;
      //console.log("value:"+ res);
      
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  handleClickColor=()=>{
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }
  handleCloseColor = () => {
    this.setState({ displayColorPicker: false })
  };
  handleChangeColor=(color)=> {
    console.log(color);
    //--debemos cambiar el color del boton mas
    //this.setState({ background: color.hex });
    this.setState({
      form: {
        ...this.state.form,
        "color": color.hex,
      },
    });
    
  }
  isEmptyObject= (obj) => {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                return false;
            }
        }
     
        return true;
  }

  // Toggle for Modal
  toggle = () => {
    this.setState({ modalImagen:!this.state.modalImagen });
  }
  showModalImagen = (event) =>{
    event.preventDefault();
    console.log("show modal imagen");
    console.log(this.state.modalImagen);
    this.setState({ modalImagen: true });
    console.log(this.state.modalImagen);
  }
  deleteImagen=()=>{
    this.setState({ form:{...this.state.form, foto_path1: ""}}); 
  }

  okUploadedModal = (filename) =>{
    console.log("ok upload" + filename);
    //---ocultamos la ventana
    this.setState({ modalImagen: false });
    this.setState({ form:{...this.state.form, foto_path1: filename}}); 
  }
  closeUploadedModal = () =>{
    console.log("cancel");
    //---ocultamos la ventana
    this.setState({ modalImagen: false });
  }
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_prod": 1,
    "nombre": "ALEX LIZARRAGA",
    "nro_doc": "00000023",
    "fecha_nac": "2022-01-13",
    "foto_path1": "1665435807.jpg",
    "dir": "Cerro Bola #319",
    "cel": "0123456789"
  },*/
  cargar_producto = (idd, ope) => {
    
    //context API
    console.log("context API");
    //const miContext = this.context;
    //console.log(miContext);
    const [ store, dispatch ] = this.context;
    const {user, paciente} = store;

    

    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post("productocrud_store", param)
    .then(res => {
      
        if(! this.isEmptyObject(res.data)){ 
            
            this.setState({form: res.data});
            //añadimos el id y ope
            this.setState({
              form: {  ...this.state.form, 
                idd: idd,  
                ope: ope,
              },
            });
            //----------- ahora colocamos en el estado global datos del paciente
            console.log("dispatch");
            dispatch({type: types.setPaciente, payload: res.data});
            //miContext
            //---------------
        }else{
          toast.error("Registro no encontrado", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    this.cargar_productos();
  }

  AsignarFoto = (filename) =>{
    /*this.setState({
      form: {
        ...this.state.form,
        "fotopath1": filename,
      },
    });*/
  }
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
   // this.cargar_productos();
  }
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //-----------------------------------------------
  aceptar_click = async () => {
    console.log("aceptar");
    this.save();

    /*confirmService.show({
      message: 'Are you sure that you want to proceed?',
      title: 'Confirmation',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
          
          this.save();
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })
    */

    
    //save();
  }
  //------------------------------------
  save =() =>{
    const [ store, dispatch ] = this.context;

      let param =  this.state.form;
      //console.log(param);
      axios2.post( "productocrud_store", param)
      .then(res => {
          if(res.data.exito === 1){ 
              toast.success(res.data.mensaje);
              //redireccionamos a la lista
              //this.props.navigate('/productolist');
          }else{
              toast.error(res.data.mensaje);
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);  
      });
    
  }
  //-------------------------------------
  render() {
    //------------ context API --------------
    const [ store, dispatch ] = this.context;
    const {user, paciente} = store;

    const styles = reactCSS({
      'default': {
        
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <ToastContainer />
        
        

        <div className="content">

          {/* ---   --- */}
          { /*JSON.stringify(this.state.form ) */}
          {/* ---   --- */}


          <section className="content-header">
            <h3>
              Editar Producto/Servicio
            </h3>
          </section>


          <section className="content">
            <form onSubmit = { this.handleSubmit }>
              
              

              <div className="row">
                <div className="col-md-3">

                  {/*<!-- Profile Image -->*/}
                  <div className="box box-primary">
                    <div className="box-body box-profile" style={{ textAlign: "center" }}>
                      <img 
                          className="profile-user-img img-responsive img-circle" 
                          src={ process.env.REACT_APP_AFILES + this.state.form.foto_path1 }
                          alt="Imagen" 
                          style={{ maxWidth: "100px"}} />
                        <p className="text-muted text-center"></p>

                        <div className="input-group ">
                          <input 
                            type="text" 
                            name="foto_path1" 
                            value={this.state.form.foto_path1} 
                            onChange={this.handleChangeForm}  
                            readOnly="" 
                            className="form-control input-sm" />

                            <span className="input-group-btn">
                              <button 
                                type="button" 
                                className="btn btn-default btn-flat"
                                onClick={ this.showModalImagen}>...</button>
                              <button 
                                type="button" 
                                className="btn btn-default btn-flat" 
                                onClick={ this.deleteImagen}
                                >
                                <i className="fa fa-trash"></i>
                              </button>
                            </span>
                            
                            <hr/>

                            


                        </div>

                        <ul className="list-group list-group-unbordered">
                          <li className="list-group-item">

                            <div className="form-group">
                              <div className="input-group">

                                <span className="text-danger">Máximo tañano 2 MB </span><br/>
                              </div>

                            </div>
                            
                          </li>




                        </ul>


                    </div>
                    {/*<!-- /.box-body -->*/}
                  </div>
                  {/*<!-- /.box -->*/}


                  
                </div>

                {/*<!-- /.col -->*/}
                <div className="col-md-9">

                  <div className="box box-primary collapsed-box">
                    <div className="box-header with-border">

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">

                            <label className="col-form-label">Cod:</label>
                            <input  
                              name="id_prod" 
                              value={this.state.form.id_prod} 
                              onChange={this.handleChangeForm} 
                              type="text" 
                              className="form-control" readOnly="readOnly" 
                              />
                          </div>
                        </div>
                      </div>

                      {/* ---   --- */}
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group has-success">
                            <label className="col-form-label">Nombre: *</label>
                            <input  type="text" 
                                className="form-control" 
                                style= {{ textTransform: "uppercase" }} 
                                name="nombre_p" 
                                value={this.state.form.nombre_p} 
                                onChange={this.handleChangeForm}                                
                                />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                          </div>
                        </div>
                      </div>

                      {/* ---   --- */}
                      <div className="row">

                        <div className="col-md-4">
                          <div className="form-group ">
                            <label className="col-form-label">Precio: *</label>
                            <NuInput  
                              className="text-right form-control" 
                              name="preciov1" 
                              value={ this.state.form.preciov1 }
                              onChange={ this.handleChangeForm }
                              classOK=""
                              classError="is-invalid"
                              valtipo="number"
                              />
                          </div>
                        </div>

                        

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="col-form-label">Color: *</label>
                            <div className="input-group ">
                              <input  name="color" 
                                value={this.state.form.color} 
                                onChange={this.handleChangeForm}
                                type="text" className="form-control" 
                                data-valtipo="LN" data-valreq="true" />
                              <span className="input-group-btn">
                                <button type="button" 
                                        className="btn  rc-color-picker-trigger" 
                                        style={{ backgroundColor: this.state.form.color}}
                                        onClick={ this.handleClickColor }
                                >&nbsp;&nbsp;&nbsp;</button>
                              </span>
                            </div>  
                              
                            {/****************/}
                            {this.state.displayColorPicker ? <div style={styles.popover}>
                              <div style={styles.cover} onClick={this.handleCloseColor} />
                              <SketchPicker 
                                  color={this.state.form.color} 
                                  onChange={this.handleChangeColor} 
                              />
                              </div> : null
                            }
                            {/****************/}
                          </div>
                        </div>

                      </div>
                      {/* ---   --- */}
                      
                      {/* ---   --- */}

                      <hr />
                        {/* ---   --- */}
                    
                       
                     
                        {/* ---   --- */}
                     
                        {/* ---   --- */}
                    </div>
                  </div>

                  <div className="box-footer">
                    <div className="form-group">
                      <div className="text-center">
                        <Button onClick={ this.aceptar_click } className="btn btn-success">
                          <i className="fa fa-floppy-o"></i>&nbsp;
                          Guardar Datos
                        </Button>
                        &nbsp;
                        <Link to="/productolist" className="btn btn-default">Cancelar</Link>
                      </div>
                    </div>
                  </div>
                  {/* ---   --- */}
                  <div id="divres"></div>
                  {/* ---   --- */}
                </div>
              </div>
              
            </form>
          </section>
              
        </div>



        <Modal isOpen={this.state.modalImagen}
          toggle={ this.toggle }
          >
          <ModalHeader style={{display: 'block'}}>
            Subir archivo png/jpg
            <span style={{float: 'right'}} onClick={()=>this.toogle()}>x</span>
          </ModalHeader>
          <ModalBody>
              <ImageUpload 
                okModal={ this.okUploadedModal}
                closeModal={ this.closeUploadedModal}
                fileName="fileName"
              />
          </ModalBody>
        </Modal>

      </div>
    
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(Producto);