import React from 'react';

import axios from 'axios';

import axios2 from '../services/axios2';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {useParams} from "react-router-dom";

import { DropdownComponent } from "../components/DropdownComponent";

import {  Link } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';

class Certificado1 extends React.Component {
    
    state = {
        dataList: [
            {id_muestra:1, lote:'SN-2', elem1:'', elem2:'21', elem3:'',elem4:'',elem5:'',elem6:''},
        ],
        idd:0,
        ope:'n',
        numcol:2,
        id_cliente:0,
        nombre_cli:'',
        nombre:'',
        mineral:'',
        fecha_cert:"2020-02-02",
        form: {
            id_muestra: "",
            lote: "",
            elem1: "",
            elem2: "",
            elem3: "",
            elem4: "",
            elem5: "",
            elem6: "",
        },
        optionSelected1:"", //option seleccionado del control
        optionSelected2:"", 
        optionSelected3:"", 
        optionSelected4:"", 
        optionSelected5:"", 
        optionSelected6:"", 
        options: [
            { value:'Ag', label: 'Ag'},
            { value:'Cu', label: 'Cu'},
            { value:'H2O',label: 'H2O'},
            { value:'Pb', label: 'Pb'},
            { value:'Sn', label: 'Sn'},
            { value:'Zn', label: 'Zn'},

        ],
    }
  
    //se ejecuta después que el componente ha sido renderizada en el DOM.
    componentDidMount() {
       
        if(this.props.match.params){
            
            let id = this.props.match.params.id;
            let ope = this.props.match.params.ope;
            this.setState({idd:id});
            this.setState({ope:ope});
            //---cargamos el formulario
            console.log("ope:"+ope);
            if(ope=='e'){
                this.fetchData(id);
            }else{
                //limpiar
                this.limpiar();
            }
            
        }
        
    }
    limpiar =()=>{
        this.setState({numcol:2});
        this.setState({nombre_cli:''});
        this.setState({nombre:''});
        this.setState({mineral:''});
        console.log(Date());
        const date = new Date();
        const defaultValue = date.toLocaleDateString('en-CA');
        this.setState({fecha_cert:defaultValue});
        
        this.setState({optionSelected1:''});
        this.setState({optionSelected2:''});
        this.setState({optionSelected3:''});
        this.setState({optionSelected4:''});
        this.setState({optionSelected5:''});
        this.setState({optionSelected6:''});
        this.setState({dataList:[]});
        
    }
    fetchData = (id) => {
        let param = { 
            id_certi: id,
        }
        
        //let token =  localStorage.getItem('token');
        //axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        axios2.post(process.env.REACT_APP_API_URL  + "certificado_find_cert", param).then(res => {
            if(res.data!=""){ 
                NotificationManager.success("Correcto");
                this.setState({id_cliente: res.data.id_cliente});
                this.setState({nombre_cli: res.data.nombre_cli});
                this.setState({nombre: res.data.nombre});
                this.setState({mineral: res.data.mineral});
                this.setState({fecha_cert: res.data.fecha_cert});
                //----------------
                this.setState({numcol: res.data.numcol});
                let elems = res.data.elems;
            
                switch(elems.length){
                    case 1:
                        this.setState({optionSelected1: elems[0]});
                        break;
                    case 2:
                        this.setState({optionSelected1: elems[0]});
                        this.setState({optionSelected2: elems[1]});
                        break;
                    case 3:
                        this.setState({optionSelected1: elems[0]});
                        this.setState({optionSelected2: elems[1]});
                        this.setState({optionSelected3: elems[2]});
                        break;
                    case 4:
                        this.setState({optionSelected1: elems[0]});
                        this.setState({optionSelected2: elems[1]});
                        this.setState({optionSelected3: elems[2]});
                        this.setState({optionSelected3: elems[3]});
                        break;
                    default: break;
                }
                
                
                
                //----------------
                this.setState({dataList: res.data.muestras});
                
            }else{
                NotificationManager.error("No existen registros");
            }
    
        }).catch(error => {
            //if(error.response.status && error.response.status===400)
            NotificationManager.error("Bad Request");
            //else NotificationManager.error("Something Went Wrong");
            //this.setState({ errors: error })
            console.log(error);
            
        });
    };

    addNewRow = (event) => {
        event.preventDefault();
        var valorNuevo= {...this.state.form};
        valorNuevo.id_muestra= this.state.dataList.length+1; //el nuevo id

        this.setState((prevState) => ({
            dataList: [...prevState.dataList, valorNuevo],
        }));
    }
   
    deteteRow = (index) => {
        this.setState({
            dataList: this.state.dataList.filter((s, sindex) => index !== sindex),
        });
        /*const miList = [...this.state.dataList];
        miList.splice(index, 1);
        this.setState({ dataList: miList });*/
        /* segunda forma */
        /*this.state.dataList.splice(index,1);
        this.state({});*/
    }
    handleChangeNombre_cli=(event)=> {    this.setState({nombre_cli: event.target.value});  }
    handleChangeNombre=(event)=> {    this.setState({nombre: event.target.value});  }
    handleChangeMineral=(event)=> {    this.setState({mineral: event.target.value});  }
    handleChangeFechaCert=(event)=> {    this.setState({fecha_cert: event.target.value});  }
    

    handleCellChange=(index, columnId, value)=>{
        const newData = [...this.state.dataList];
        newData[index][columnId] = value;
        
        console.log(newData);
        //setData({  data: newData    });
        this.setState({ datalist: newData });
    }
    //---------------------------SAVE UPDATE-------------------------------------------------
    handleFormSubmit = (e) => {
        e.preventDefault();
        console.log("handlesubmit");
        console.log(this.state);
        //--verificacion de cadena vacia
        if(this.state.nombre_cli==='' || this.state.mineral==='')
        {
            NotificationManager.warning("Porfavor llene los campos nombre_cli y mineral");
            return false;
        }
        //---verificamos que todos los lotes tengan valor
        for(var i=0;i<this.state.dataList.length;i++)
        {
                if(this.state.dataList[i].lote==='')
                {
                    NotificationManager.warning("El campo lote es requerido");
                    return false;
                }
        }

        let param = { 
            idd: this.state.idd,
            ope: this.state.ope,
            id_cliente: this.state.id_cliente,
            id_u: localStorage.getItem('user.id_u'),
            nombre_cli: this.state.nombre_cli,
            procedencia: this.state.nombre_cli,
            nombre: this.state.nombre,
            mineral: this.state.mineral,
            fecha_cert: this.state.fecha_cert,

            muestras: JSON.stringify(this.state.dataList),

            op1: this.state.optionSelected1,
            op2: this.state.optionSelected2,
            op3: this.state.optionSelected3,
            op4: this.state.optionSelected4,
            op5: this.state.optionSelected5,
            op6: this.state.optionSelected6,
            
        }
        console.log(param);
        
        //--la ruta cambia si es editar
        let ruta = "certificado_store";
        if(this.state.ope=='e'){
            ruta = "certificado_update";
        }

        console.log(process.env.REACT_APP_API_URL + ruta);

        //axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
        axios2.post(process.env.REACT_APP_API_URL  + ruta, param).then(res => {
            if(res.data.exito==1){ 
                NotificationManager.success(res.data.men);
                //--mostramos ventana del certificado
                let fileURL = process.env.REACT_APP_API_URL + "certificado_reciboPDF/"+ res.data.id;
                let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
                window.open(fileURL,'',windowFeatures);
            }else{
                NotificationManager.error(res.data.men);
            }

        }).catch(error => {
            //if(error.response.status && error.response.status===400)
            NotificationManager.error("Bad Request");
            //else NotificationManager.error("Something Went Wrong");
            //this.setState({ errors: error })
            console.log(error);
            
        });
    }
    //---mostrar el modal
    form_show_elem=()=>{
        this.setState({modalElemento: !this.state.modalElemento});
    }
    form_close_elem=()=>{
        this.setState({modalElemento: false});
    }
    /** Function that will set different values to state variable
     * based on which dropdown is selected
     */
    handleChangeOption1 = (event) => {
        this.setState({optionSelected1:event.target.value});
    };
    handleChangeOption2 = (event) => {
        this.setState({optionSelected2:event.target.value});
    };
    handleChangeOption3 = (event) => {
        this.setState({optionSelected3:event.target.value});
    };
    handleChangeOption4 = (event) => {
        this.setState({optionSelected4:event.target.value});
    };
    handleChangeOption5 = (event) => {
        this.setState({optionSelected5:event.target.value});
    };
    handleChangeOption6 = (event) => {
        this.setState({optionSelected6:event.target.value});
    };
    contadormas=(event)=>{
        event.preventDefault();
        let cant= this.state.numcol;
        cant= cant+1;
        if(cant === 6){
            //--llego a su limite no puede mas
        }else{
            //-- lo incrementeamos
            this.setState({numcol:cant})
        }
    }
    contadormenos=(event)=>{
        event.preventDefault();
        let cant= this.state.numcol;
        cant= cant-1;
        if(cant == 1){
            //--llego a su limite no puede mas
        }else{
            //-- lo incrementeamos
            this.setState({numcol:cant})
        }
    }
    //----------------------------------------------------------------------------
    render() {
        let { dataList } = this.state//let { notes, date, description, taskList } = this.state
    return(
        <div className="col-md-12 col-xs-12 " style={{marginTop:"70px",minHeight:"700px"}}>
        <NotificationContainer/>
        <div className="container">
            <div className="">
                <div className="row justify-content-center">
                    <div className="col-md-11 col-md-offset-2">

                        <form onSubmit={this.handleFormSubmit} onChange={this.handleChange} >
                            <div className="card" style={{border: '10px solid #aaaaaac9'}}>
                                <h4 className="header header-primary text-center">
                                    Certificado Nro. <b>{this.state.idd}</b>
                                </h4>
                                <div className="card-body">
                                    <table className='tabla_basic' style={{width:'100%'}} >
                                        <tbody>
                                            <tr>
                                                <td>Procedencia:</td>
                                                <td>
                                                    <input type="text" value={this.state.nombre_cli} onChange={this.handleChangeNombre_cli} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Nombre:</td>
                                                <td>
                                                    <input type="text" value={this.state.nombre} onChange={this.handleChangeNombre} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Mineral:</td>
                                                <td>
                                                    <input type="text" value={this.state.mineral} onChange={this.handleChangeMineral} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fecha Cert:</td>
                                                <td>
                                                    <input type="date" value={this.state.fecha_cert} onChange={this.handleChangeFechaCert} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Caracteristicas:</td>
                                                <td>PAQUETE CERRADO Y SELLADO</td>
                                                <td>
                                                    <div style={{width:'150px'}}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                            <button onClick={ this.contadormenos }><i className="fa fa-arrow-left"></i></button>
                                                            </div>

                                                            <h4 style={{width:"40px"}} className='text-center'>{this.state.numcol + 1}</h4>

                                                            <div className="input-group-append">
                                                            <button onClick={ this.contadormas }><i className="fa fa-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <hr/>
                                    
                                    <table className="table table-hover text-left">
                                        <thead>
                                            <tr>
                                                <td><h5>Nº</h5></td>
                                                <td style={{width:'200px'}} >
                                                    <h5>Nro Lote</h5>
                                                </td>
                                                <td>
                                                <DropdownComponent
                                                    options={this.state.options}
                                                    onChange={this.handleChangeOption1}
                                                    value={this.state.optionSelected1}
                                                    name={""}
                                                />
                                                    
                                                </td>
                                                <td>
                                                    <DropdownComponent
                                                        options={this.state.options}
                                                        onChange={this.handleChangeOption2}
                                                        value={this.state.optionSelected2}
                                                        name={""}
                                                    />
                                                </td>
                                                <td>
                                                    <DropdownComponent
                                                        options={this.state.options}
                                                        onChange={this.handleChangeOption3}
                                                        value={this.state.optionSelected3}
                                                        name={""}
                                                    />
                                                </td>
                                                {this.state.numcol>=3 &&  <td>
                                                    <DropdownComponent
                                                        options={this.state.options}
                                                        onChange={this.handleChangeOption4}
                                                        value={this.state.optionSelected4}
                                                        name={""}
                                                    />
                                                    </td>
                                                }
                                                {this.state.numcol>=4 &&  <td>
                                                    <DropdownComponent
                                                        options={this.state.options}
                                                        onChange={this.handleChangeOption5}
                                                        value={this.state.optionSelected5}
                                                        name={""}
                                                    />
                                                    </td>
                                                }
                                                {this.state.numcol>=5 &&  <td>
                                                    <DropdownComponent
                                                        options={this.state.options}
                                                        onChange={this.handleChangeOption6}
                                                        value={this.state.optionSelected6}
                                                        name={""}
                                                    />
                                                    </td>
                                                }

                                                {/*<!-- -->*/}
                                                <td>
                                                <button className="btn btn-success  btn-fab btn-round btn-fab-mini  " data-toggle="tooltip"
                                                    onClick={(e) => { this.addNewRow(e) }}>
                                                    <i className="fa fa-plus"></i>
                                                    <div className="ripple-container"></div>
                                                </button>
                                                </td>
                                            </tr>
                                       
                                        </thead>
                                       
                                        <tbody>
                                            {  dataList.map((item, index) => (
                                                <tr key={ item.id_muestra }>
                                                    <td>{ index + 1 }</td>
                                                    <td>
                                                        <input type="text" className='form-control' 
                                                        onChange={(e) => this.handleCellChange(index,"lote", e.target.value)} 
                                                        value={item.lote} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className='form-control' 
                                                        onChange={(e) => this.handleCellChange(index,"elem1", e.target.value)} 
                                                        value={item.elem1} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className='form-control' 
                                                        onChange={(e) => this.handleCellChange(index,"elem2", e.target.value)} 
                                                        value={item.elem2} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className='form-control' 
                                                        onChange={(e) => this.handleCellChange(index,"elem3", e.target.value)} 
                                                        value={item.elem3} />
                                                    </td>

                                                    {this.state.numcol>=3 && 
                                                        <td>
                                                            <input type="text" className='form-control' 
                                                            onChange={(e) => this.handleCellChange(index,"elem4", e.target.value)} 
                                                            value={item.elem4} />
                                                        </td>
                                                    }
                                                    {this.state.numcol>=4 &&  
                                                        <td>
                                                            <input type="text" 
                                                            className='form-control' 
                                                            onChange={(e) => this.handleCellChange(index,"elem5", e.target.value)} 
                                                            value={item.elem5} />
                                                        </td>
                                                    }
                                                    {this.state.numcol>=5 && 
                                                        <td>
                                                            <input type="text" className='form-control' 
                                                            onChange={(e) => this.handleCellChange(index,"elem6", e.target.value)} 
                                                            value={item.elem6} />
                                                        </td>
                                                    }

                                                    
                                                    <td>
                                                    <button className="btn btn-danger  btn-fab btn-round btn-fab-mini  " data-toggle="tooltip" title=""
                                                        onClick={() => { this.deteteRow(index)}}>
                                                        <i className="fa fa-trash"></i>
                                                        <div className="ripple-container"></div>
                                                    </button>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                            
                                        </tbody>
                                    </table>
                                    
                                
                                
                                    <div className="text-center">
                                        
                                        {this.state.ope=='e'?(
                                            <button className="btn btn-success" type="submit">Modificar</button>
                                        ):(
                                            <button className="btn btn-primary" type="submit">Guardar</button>
                                        )
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

        


        <button  className="btn btn-round btn-fab   btn-success cpulse" id="topbtn" title="Go to top"
            style={{display: "block"}}>⇑</button>
    </div>
    );
}

}
//------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
    return(props)=>{

       const match  = {params: useParams()}; 
       return <Children {...props}  match = {match}/>
   }
 }
export default withRouter(Certificado1);