import * as React from "react";

const Diente12 = (props) => (
  <svg style={{ width: "inherit", height: "inherit" }}
          version="1.1"
          viewBox="0 0 18.268937 27.425163"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          {...props}
      >
   
   
   <path
      fill="#000"
      fillRule="evenodd"
      d="M13.205.083c-1.213.254-7.423 2.405-8.45 3.232C3.776 4.105.248 4.49 0 7.402.139 12.007 3.368 19.113 3.681 19.9c.632 1.487 2.312 5.428 3.902 6.516 1.399.956 2.76.966 3.616.972 1.391-.234 2.04-1.154 2.496-2.477.415-4.132 2.55-15.452 3.902-18.653-.094-3.222-.593-6.74-4.392-6.174m3.358 6.152c-1.566 3.936-2.854 12.572-3.664 18.57-.61 1.296-.748 1.779-1.758 1.927-.794.007-2.27-.165-3.071-.877-1.476-1.312-3.428-5.615-3.726-6.59C3.75 17.588 1.187 10.918.874 7.37.822 5.6 4.33 4.518 5.156 4.01 6.307 3.303 12.584.982 13.613.75c2.894-.035 3.153 3.534 2.95 5.485M7.28 18.018c-.823-1.765-3.277-6.298-3.543-5.727.3 1.078 3.066 7.354 4.159 8.242.776-.345.08-3.116.797-3.312.462-.126 1.353 5.284 2.354 5.311.352-.183.571-.746.741-1.503.428-1.907 1.805-10.992 1.527-11.235-.92.299-1.892 10.708-2.424 10.936-.912-.774-1.307-5.247-2.38-5.33-.597.231-.554 2.428-.708 2.759-.076.163-.186.383-.523-.141"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={0.012}
      d="M13.623.71c-3.808 1.246-7.569 2.688-11.1 4.592-.698.44-1.548.992-1.636 1.893 0 1.194.351 2.358.62 3.512.858 3.17 1.96 6.27 3.129 9.336.761 1.763 1.602 3.516 2.75 5.064.492.673 1.172 1.231 2.006 1.403.722.197 1.52.332 2.249.107.674-.316.936-1.096 1.21-1.734.293-1.298.383-2.634.608-3.946.658-4.286 1.311-8.59 2.492-12.77.265-.954.73-1.88.664-2.897-.006-1.354-.181-2.886-1.201-3.88-.48-.462-1.146-.63-1.791-.68Zm-.317 9.113c.051 1.037-.154 2.073-.257 3.105-.373 2.742-.757 5.489-1.31 8.199-.134.475-.232 1.074-.685 1.354-.359.01-.492-.464-.65-.714-.652-1.396-.914-2.936-1.517-4.35-.047-.2-.311-.279-.39-.054-.254.587-.138 1.263-.218 1.886-.054.43.004.971-.375 1.273-.94-1.038-1.481-2.366-2.128-3.594-.712-1.5-1.452-2.995-1.993-4.566-.07-.225.235.033.266.084.924 1.188 1.62 2.543 2.353 3.855.384.63.6 1.358 1.044 1.95.1.187.33.111.346-.082.24-.86.122-1.829.567-2.627.213-.267.503.081.625.268.581 1.01.764 2.191 1.138 3.286.185.56.329 1.204.766 1.624.202-.104.171-.448.256-.645.627-2.966.94-5.995 1.61-8.95.14-.428.215-.99.552-1.302Z"
    />
    
    
  
  </svg>
);
export default Diente12;