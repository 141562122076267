import * as React from "react";

const Diente11 = (props) => {

  //"#fff"
  let color= props.color;
  return (
  <svg style={{ width: "inherit", height: "inherit" }}
          version="1.1"
          viewBox="0 0 20.040591 29.196816"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          
          {...props}
          
      >
   
   
   <g >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16.448.3C15.168.343 3.245-.27 2.052.41.912 1.058.09 2.446-.058 4.915c.429 6.706 1.987 17.416 5.772 22.251 1.25 1.269 2.168 1.449 3.038 1.614 1.415-.071 2.41-.835 3.11-2.144 1.168-4.277 5.673-15.383 7.625-18.504.484-3.413.632-7.466-3.04-7.832m1.991 7.659c-2.302 3.855-5.463 12.25-7.366 18.419-.855 1.252-1.123 1.65-2.263 1.703-.808-.142-2.32-1.274-3.008-2.174-2.15-3.83-4.026-9.643-5.049-20.312-.062-1.172.718-3.862 1.581-4.378.701-.546 13.32-.086 14.409-.138 2.654.757 2.254 4.862 1.696 6.88M6.797 18.024c-.942-1.04-2.123-5.021-2.707-4.68-.143.6 1.637 4.85 2.462 6.369.852-.219.636-3.185 1.401-3.258.493-.046.492 7.65 1.505 7.865.391-.127.715-.68 1.024-1.446.779-1.929 2.201-7.24 1.962-7.547-.989.142-2.239 6.923-2.82 7.065-.789-.986-.167-7.359-1.507-7.658-.618.213-1.102 2.566-1.32 3.29"
        clipRule="evenodd"
      />
      <path
        fill={color}
        stroke="#000"
        strokeWidth={0.017}
        d="M6.439 1.01c-1.27.05-2.559-.08-3.816.115C1.82 1.33 1.6 2.268 1.28 2.924.785 4.119.662 5.432.864 6.706c.682 6.071 1.599 12.251 4.22 17.83.45 1.08 1.15 2.055 2.132 2.71.7.545 1.694 1.15 2.567.601 1.318-.835 1.567-2.506 2.076-3.855 1.667-4.933 3.443-9.851 5.758-14.522.49-.87 1.019-1.758 1.097-2.78.11-1.402.293-2.923-.362-4.226-.395-.761-1.144-1.504-2.073-1.378-3.28-.075-6.56-.078-9.84-.077ZM4.155 13.362c.636.37.79 1.2 1.135 1.8.451.969.875 1.956 1.45 2.859.316-.651.437-1.506.726-2.223.176-.349.259-.98.726-1.025.538.296.525 1.052.659 1.578.313 1.867.2 3.788.588 5.642.019.197.21.596.358.24.892-2.174 1.233-4.568 2.366-6.641.065-.216.369-.26.293.024-.36 2.155-.998 4.27-1.664 6.351-.343.798-.513 1.824-1.304 2.31-.383-.12-.42-.659-.534-.991-.466-2.208-.452-4.494-.897-6.705-.075-.323-.387-.037-.415.156-.404.9-.34 1.98-.882 2.811-.27.216-.409-.323-.524-.484-.816-1.805-1.659-3.624-2.14-5.547-.002-.048-.017-.16.059-.155Z"
      />
    </g>
    
    
  
  </svg>
  )
}
export default Diente11;