import React from "react";
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import  NuDropdown from "../../components/nubox/NuDropdown";
import  NuDropdownIcon from "components/nubox/NuDropdownIcon";
import  PiezaDental  from  "components/PiezaDental";
import { withRouterJim } from "router/withRouterJim";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const cbo=[
  {  value:'1', label: 'label1', precio:0, color:"#ccc"},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]
let formInitial = {
  idd:0,
  ope:'n',
  id_tra:0,
  id_prod: 0,  
  pieza:'',    
  partes:'00000',
  interno_sn: 0, /*radio */
  cant:0,
  precio:'',
  total: 0,
};
/* prestaciones
2: Object { value: 7, label: "ORTODONCIA", preciov1: 65, … }​
3: Object { value: 13, label: "OTRO 1", preciov1: 44, … }
 */
class TratamientoDetModal extends React.Component {
  

  state = {
    modalInsertar:false,
    id_tra:0,  /*maestro */
   
    form: formInitial,
    errorPieza:false,
    
    prestaciones:[], /* cbo */
    
  };
  //--------------- PUBLICO desde afuera se utiliza -
  setPieza=(pieza)=>{
    let cn = this.contarPiezas(pieza.toString());
    console.log("contarPiezas"+ cn);
    this.setState({
      form: {  ...this.state.form, 
        pieza: pieza,
        cant:cn,  
      },
    });
  }
  //---------------------------------
  //  ej 23    = 1
  //  ej 23,25,27    =3
  //  el parametro de entrada es cadena
  contarPiezas=(piezas)=>{
    let cant = 0;
    if(piezas !==''){
      const miArr = piezas.split(',');
      cant = (miArr.length===0)?   1: miArr.length;
    }
    return cant;
  }
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount Modal");
    //--limpiamos el array de prueba
    //this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    
    this.cargar_prestaciones();
    
    //id_tra
    console.log("id_tra:" + this.props.id_tra);

    this.setState({ id_tra: this.props.id_tra,   });    
  }
  //---- retorna un formulario con valores iniciales
  limpiar = () =>{
    this.setState({form: formInitial});
  }
  //-------------------------
  cargar_form = (idd, ope) => {
    //context API
    console.log("cargar_form");   

    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param); 
    axios2.post( "tradet_store", param )
    .then(res => {
      
        if(! this.isEmptyObject(res.data)){ 
            
            this.setState({form: res.data});
            //añadimos el id y ope
            this.setState({
              form: {  ...this.state.form, 
                idd: idd,  
                ope: ope,
              },
            });
            //---------------
        }else{
          toast.error("Registro no encontrado", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //------------ cambiamos la fecha
  /*handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      //console.log("cheched:"+ e.target.checked);
      let res = e.target.checked? 1:0;
      //console.log("value:"+ res);
      
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  //--------------------------------------------------------------
  handleChangeFormPrestacion = (e) => {
    
    // cambiamos el precio cada vez que cambie de prestacion
    //console.log ("e.target.item");
    //console.log(e.target.item);

    let precio = 0;
    //------------------si existe
    if( e.target.item !==undefined ){
      precio= e.target.item.preciov1;
    }
    const total = precio *this.state.form.cant;
    //-------------------actualizamos el estado
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
        "precio":precio,
        total: total,
      },
    }); 

  };
  //--------------------
  handleChangeFormPrecio= (e) => {
    //---calculamos los valores
    const precio = e.target.value;
    const total = precio *this.state.form.cant;
    //-------------------actualizamos el estado
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
        total: total,
      },
    });
  }
  //------------------------------------------------------
  handleChangeFormPza = (e) => {
    //---- lo validamos
    let flag = this.PzaPatternValidation(e.target.value);
    console.log("match:"+ flag);
    
    //--- contamos, cuantos numeros hay
    let cant= this.contarPiezas(e.target.value);
    let precio = this.state.form.precio;

    //---calculamos los valores
    let total = cant * precio;
    //const obj = this.calcular();
    
    //----------------actualizando y calculando a la vez
    this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          cant: cant,
          total: total,
        },
        errorPieza: !flag,
        
    });

    //this.calcular(cant, precio);
    
  };
  //--- pueden ser varias piezas 2,5,4
  PzaPatternValidation = pieza => {
    const regex = new RegExp('^[0-9]*([,][0-9]+)*$');    
    return regex.test(pieza);
  };
  //-----------------------------------------------------
  changePiezaDental =(cad5)=>{
    console.log("cad5:"+ cad5);
    this.setState({
      form: {
        ...this.state.form,
        "partes": cad5,
      },
    });    
  }
  
  //======================================================
  showModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: true});
  }
  closeModal = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: false});
  }

  //--------------- 
  //--- hora, solo se puede utilizar una ves setStare en una funcion 
  //--- cuando se utiliza multiples veces, solo hace caso la ultima, tomar en cuenta
  nuevo =() =>{
    console.log("nuevo");
    
    //this.limpiar();
    this.showModal();
    
    this.setState({
      form: {
        ...formInitial,
        "idd":0,
        "ope":'n',
      },
    });
    
    console.log(this.state.form);
    
  }
  //---------------
  editar =(idd)=>{
    console.log("editar:" + idd);
    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post( "tradet_store", param).then(res => {
      console.log(res.data);

        if(res.data!==null){ 
            //NotificationManager.success("Correcto");
            this.setState({
              form: {
                ...res.data,
                "idd": idd,
                "ope": 'e',
              },
            });

            //----
            this.showModal();            
            
        }else{
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }

  eliminar =(idd)=>{
    console.log("editar:" + idd);
    let param = { 
      idd: idd,
      ope:'f'
    }
    console.log(param);
    axios2.post( "tradet_store", param).then(res => {
      console.log(res.data);

        if(res.data!==null){ 
            //NotificationManager.success("Correcto");
            this.setState({
              form: {
                ...res.data,
                "idd": idd,
                "ope": 'x',
              },
            });

            //----
            this.showModal();
            //this.disabled();
            
        }else{
          toast.error("No existe el registro", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  }
  //----cargamos los datos desde la base de datos
  /*[{ "value": 1, "label": "PERDIDO", "preciov1": 50, "color": "#fff"},    ]
  */
  cargar_prestaciones = async() => {
    let param = { }
    
    await axios2.post("cbo_producto", param).then(res => {
      console.log(res.data);
        //if(res.data.length>0){ 
            this.setState({prestaciones: res.data});            
        //}
    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
    });
  };
  //----------------------------
  calcular=()=>{
    let total = this.state.form.cant * this.state.form.precio;
    return {total: total};
  }
  calcularEx=(cant, precio)=>{
    let total = cant * precio;
    return {total: total};
  }
  //--------------------------------------
  validar = () =>{
    console.log("validar");
    if( this.state.form.id_prod===0){
      toast.error( "Producto no valido", {position: toast.POSITION.TOP_RIGHT});
      return true;
    }

    return false;
  }
  //-------------------------------------
  formSave = () => {
    // the item selected
    console.log("save");

    let error = this.validar();
    console.log(error);
    if(error===true){
      return;
    }
    
    let param = {...this.state.form,
                  id_tra: this.props.id_tra,
                };
    console.log(param);

    axios2.post( "tradet_store", param).then(res => {
        let response = res.data;
        console.log(res.data);
        if(response.exito===1){ 
            
          toast.success( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
          //---llamamos a la funcion externa
          this.closeModal();
          this.props.okModal(); //callback
        }else{
            
          toast.error( response.mensaje, {position: toast.POSITION.TOP_RIGHT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request - ");
        console.log(error);
        
    });

  }
  //-----------------------------------------------------------------------------------------------
  render() {
    const {form} = this.state;
    let clase ='';
    let titulo= '';
    let btnClass='primary';
    let btnText='Aceptar';
    switch(this.state.form.ope){
      case 'n': titulo='Nuevo'; clase='header-verde'; break;
      case 'e': titulo='Editar'; clase='header-naranja'; break;
      case 'x': titulo='Eliminar'; clase='header-rojo'; btnClass='danger'; btnText="Eliminar"; break;
      default: break;
    }
    return (
      <div>
        <ToastContainer />
        
        {/*--- modal---- */}
        <Modal isOpen={this.state.modalInsertar} toggle={this.closeModal} >
          <ModalHeader toggle={this.closeModal} className= { clase } 
          >
            <h4 className="modal-title">
            { titulo }
            </h4>
          </ModalHeader>

          <ModalBody style={{ background: "#F7F7F7"}}>
            
              <div>
              { 
                //JSON.stringify(this.state.form) 
              } </div>
              <br/>
              { 
                //JSON.stringify(this.props.id_tra)
              }
              <table style={{ width: "100%" }} cellSpacing="0" cellPadding="2" align="center">
                <tbody>
                  {/*   fila 1  */}
                  <tr>
                    <td>
                      

                      <div className="row mb-2">
                        <div className="col-5">
                          <b>Prestación:</b>
                        </div>
                        <div className="col-7">
                          {/* 
                          <NuDropdown
                            options={this.state.prestaciones}
                            onChange={this.handleChangeFormPrestacion}
                            value={this.state.form.id_prod}
                            name="id_prod"
                          />
                          */}
                          <NuDropdownIcon
                            options={this.state.prestaciones}
                            name="id_prod"
                            value={this.state.form.id_prod}
                            onChange={this.handleChangeFormPrestacion}
                            style={{width:"100%"}}
                            className="dropIcon"
                          />

                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-5">
                          <b>Diente(s) Nº:</b>
                        </div>
                        <div className="col-7">
                          <input type="text" 
                              className="form-control" 
                              style={{ fontSize: "14pt"}}
                              name="pieza" 
                              value={this.state.form.pieza}
                              onChange={this.handleChangeFormPza}
                              />
                            <div>
                                { this.state.errorPieza &&
                                  <div className="text-danger">Valor no valido</div>  
                                }
                            </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>Interno s/n:</b>
                        </div>
                        <div className="col-7">
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" 
                                name="interno_sn"
                                value = {this.state.form.interno_sn }
                                checked={this.state.form.interno_sn===1 }
                                onChange={this.handleChangeForm}   
                            />
                          </div>
                        </div>
                      </div>

                      <div id="FormMarcarPieza" >
                        <div className="row">
                          <div className="col-5">
                            <p><b>Seleccione las partes</b></p>
                          </div>

                          <div className="col-7">
                            <PiezaDental
                              value={this.state.form.partes}
                              onChange={this.changePiezaDental}
                            />

                          </div>
                        </div>

                      
                      </div>

                {/*  <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="control-label"><b>Especificaciones</b></label>
                      <textarea name="especificaciones" className="form-control" rows=2"></textarea>
                    </div>
                  </div>
                </div>  */}

                <div className="row">
                    <div className="col-5">
                      <b>Cantidad</b>
                    </div>
                    <div className="col-7">
                      <input 
                          type="text"                           
                          className="text-right form-control" 
                          readOnly
                          name="cant" 
                          value={this.state.form.cant}
                          onChange={this.handleChangeForm}
                          />
                    </div>
                </div>

                <div className="row form-group mt-2">
                  <div className="col-5">
                    <b>Precio unitario:</b>
                  </div>
                  <div className="col-7 ">
                    <input 
                        type="text"
                        className="text-right form-control" 
                        name="precio" 
                        value={this.state.form.precio}
                        onChange={this.handleChangeFormPrecio}
                    />
                  </div>
                </div>

                <div className="row form-group mt-2">
                  <div className="col-5">
                    <b>Total:</b>
                  </div>
                  <div className="col-7 ">
                    <input 
                        type="text"
                        className="text-right form-control" 
                        readOnly
                        name="total" 
                        value={this.state.form.total}
                        onChange={this.handleChangeForm}
                    />
                  </div>
                </div>
          


              </td>
            </tr>
            {/*   fila 3  */}
            
            
          </tbody>
        </table>

             
            
          </ModalBody>
          <ModalFooter>
            <Button color={ btnClass } onClick={ this.formSave}>{ btnText }</Button>
            {' '}
            <Button color="secondary" onClick={this.closeModal}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default  TratamientoDetModal;