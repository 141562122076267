import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";
import ImageUploadSingle from "components/nubox/ImageUploadSingle"; 

import confirmService from 'components/confirmService';
import ModalMedico from "views/medicos_crud/ModalMedico"



import user from 'assets/img/user.jpg';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
class MenuDebug extends React.Component {  

  modalRef = React.createRef();//create ref
  
  state = {
    modalInsertar: false,
    form: {},
    data: [],/* lista  */
    
  };
  
  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 
  }

  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  
  //====================================================== 

 
  //-------------------------------------
  render() {
    
    return (
      <div>
        <ToastContainer />
        <div className="content">

          {/*<!--- ------------------------------->*/}
          <div className="row">
            <div className="col-lg-6">

              <div className="main-card mb-3 card">
                
                <table className="table table-striped table-hover" width="100%" align="center">
                  <tbody>
                    <tr>
                      <th>operacion</th>
                      <th>descrip</th>

                    </tr>

                    <tr>
                      <td>
                        
                        <Link to={"/reset"} className="btn btn-warning">
                          <i className="fa fa-fw fa-list-alt"></i> Reset sistema
                        </Link>
                      </td>
                      <td>Elimina los registros de todas las tablas, para iniciar de 0</td>
                    </tr>

                    <tr>
                      <td>
                        
                        <Link to={"/compare_db"} className="btn btn-primary">
                          <i className="fa fa-fw fa-list-alt"></i> Comparador de DB
                        </Link>
                      </td>
                      <td>comparador de base de datos</td>
                    </tr>

                  </tbody>
                </table>

                
                <div className="d-block text-right card-footer">
                 
                </div>
              </div>
            </div>
            <div>

            </div>
          </div>

          {/*<!-- -------------------------------------------------->*/}

        </div>

      </div>
    );
  }
}
  
export default MenuDebug;