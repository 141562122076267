import React from "react";

import axios2 from 'services/axios2';

import { useParams } from "react-router-dom";
import { Link, hashHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import ImageUploadSingle from "components/nubox/ImageUploadSingle";
import NuLoading from 'components/nubox/NuLoading';
import confirmService from 'components/confirmService';
import ModalMedico from "views/medicos_crud/ModalMedico"



import user from 'assets/img/user.jpg';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

const initialForm = {
  idd: 0,
  ope: 'n',
  pro: false,
  prov: false,
  table_schema:'',
}

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
class Reset extends React.Component {

  modalRef = React.createRef();//create ref

  state = {
    modalInsertar: false,
    cargando:false,
    divres: '',
    form: initialForm,
    data: [],/* lista  */

  };

  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({ data: [] });

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');

    //this.setState({fechai: defaultValue}); 
    //this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 

    //this.cargar_globalx();

    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    console.log(this.modalRef);
  }

  //------------ cambiamos la fecha
  /*handleChange = event =>{
    this.setState({ name: event.target.value});
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleChangeCheck = (e) => {
    //
    console.log(e.target.checked);
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.checked,
      },
    });
  };

  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_medico": 1,
        "nombre": "Dr. Gerson Abner Villafuerte Oporto",
        "dir": "",
        "tel": "",
        "cel": "",
        "foto_path1": ""
        "especialidad": "Ortodoncia"
  },*/
  /*cargar_globalx = () => {
    let param = { 
      
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "globalx_get_global", param)
    .then(res => {
      //console.log(res.data);

        if(res.data !== null ){ 
            //NotificationManager.success("Correcto");
            console.log(res.data);
            this.setState({form: res.data});
            //console.log(this.state.medicos);
        }else{
            this.setState({form: initialForm});
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };/*
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    //this.cargar_globalx();
  }
  /************************************************ */


  save = () => {

    //let param =  {};
    let param = this.state.form;
    param.idd = 1;
    param.ope = 'n';

    this.setState({ cargando: true }); 
    //console.log(process.env.REACT_APP_API_URL);


    axios2.post(process.env.REACT_APP_API_URL + "debug_compare_db_store", param)
      .then(res => {
        this.setState({ cargando: false }); 
        if (res.data.exito === 1) {
          //toast.success(res.data.mensaje);
          this.setState({ divres: res.data.mensaje });
        } else {
          toast.error(res.data.mensaje);
        }

      }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);

      });

  }
  //-------------------------------------
  render() {

    return (
      <div>
        <ToastContainer />
        <div className="content">

          { //JSON.stringify(this.state.form)
          }
          {/*<!--- ------------------------------->*/}
          <div className="row justify-content-center">

            <div className="col-md-6">



              {/*<!-- /.card -->*/}
              <form onSubmit={this.handleSubmit}>
                
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Comprobar base de datos</h3>
                      Copiar el json DB original, esta en este link
                      localhost:8080
                      <h4>Copie el esquema completo, generado en localhost:8080</h4>
                      
                      <Link to={ process.env.REACT_APP_API_URL  + "debug_compare_db_get_db"} target="_blank" className="btn btn-warning">
                          <i className="fas fa-link"></i> &nbsp;link json Localhost
                      </Link>
                    </div>
                    {/*<!-- /.card-header -->*/}

                    <div className="card-body">
                      <textarea 
                        className="form-control"
                        rows="5"
                        name="table_schema"  
                        value={this.state.form.table_schema}
                        onChange={this.handleChangeForm}></textarea>
                    </div>
                    {/*<!-- /.card-body -->*/ }

                    <div className="card-footer text-center">
                      <button className="btn btn-primary" onClick={()=>this.save()} >Comparar con el server</button>
                    </div>


                    <NuLoading 
                      className="oso"
                      show={this.state.cargando}
                      text="Cargando..."
                    />
                    {/*<!-- resultado jquery-->*/}
                    <div id="divres1" dangerouslySetInnerHTML={{__html : this.state.divres}} ></div>


                  </div>

              </form>
            </div>


          </div>

          {/*<!-- -------------------------------------------------->*/}

        </div>

      </div>
    );
  }
}

export default Reset;