import { React, Component } from "react";

const FondoSvg = (props) => (
  <svg style={{ width: "inherit", height: "inherit" }}
    
    viewBox="0 0 451.04965 283.10562"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M115.69 207.148s6.064-7.431 16.96-8.401c10.897-.97 44.266 17.705 44.266 17.705s6.323 1.813 9.948-14.535-6.955-59.596-13.512-81.747c-6.557-22.151-9.949-35.399-19.301-48.556-9.353-13.157-23.993-22.27-38.52-22.192-14.528.078-25.994 8.14-36.777 21.717-10.783 13.577-12.995 38.34-18.705 60.396-5.71 22.056-14.797 57.01-11.096 71.65 3.7 14.641 10.937 14.425 10.937 14.425s29.795-17.929 39.63-17.912c9.835.016 16.17 7.45 16.17 7.45z"
        style={{
          fill: "none",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M115.13 49.145c-9.211.059-18.027 4.048-25.02 9.885-4.842 4.037-9.133 8.74-12.815 13.853-5.958 8.83-8.54 19.344-10.902 29.584-2.565 11.458-4.774 22.992-7.83 34.333-3.641 14.522-7.362 29.071-9.468 43.913-.844 6.606-1.454 13.328-.67 19.97.841 4.838 2.482 9.73 5.691 13.532 1.46 1.63 3.392 3.128 5.618 3.36 5.463-2.897 10.755-6.116 16.277-8.9 6.465-3.262 12.99-6.566 20.018-8.46 4.348-1.167 9.051-.174 13.01 1.898 2.406 1.217 4.546 2.892 6.467 4.787.688.137 1.023-.862 1.606-1.167 3.35-3.1 7.5-5.37 11.958-6.377a18.64 18.64 0 0 1 10.01.21c8.705 2.406 16.924 6.234 25.034 10.156 4.299 2.055 8.429 4.435 12.698 6.546 1.316.65 2.729-.351 3.815-1.033 2.67-2.193 3.965-5.577 5.11-8.733 1.196-3.53 1.962-7.223 1.88-10.963.14-5.582-.152-11.168-.97-16.693-2.656-19.57-7.396-38.797-12.82-57.766-2.89-9.83-5.717-19.691-9.161-29.345-4.21-11.367-10.319-22.362-19.575-30.393-8.21-7.244-18.854-12.305-29.96-12.197Z"
        style={{
          fill: "#faa",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.0317038,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M110.344 117.585c-.93-2.524-3.208-8.358-5.895-12.323-2.688-3.966-5.785-6.062-10.145-8.732-.046-1.999 3.196-5.336 4.57-5.464 10.174 5.701 10.493 17.766 11.47 26.519zM112.897 148.31s-5.634-23.49-13.443-28.623c-7.809-5.133-8.771-3.997-10.555-4.986-1.111-1.382.196-6.84 1.824-8.266 1.796-.023 2.651.314 7.258 3.013 4.606 2.698 7.991 6.64 11.112 13.5 3.12 6.858 3.804 25.362 3.804 25.362zM117.785 148.22s5.635-23.49 13.443-28.623c7.81-5.133 8.771-3.997 10.555-4.986 1.111-1.382-.195-6.841-1.824-8.266-1.796-.023-2.65.314-7.257 3.012-4.607 2.699-7.992 6.641-11.113 13.5-3.12 6.86-3.804 25.363-3.804 25.363zM119.728 118.12c.93-2.525 3.208-8.36 5.896-12.324 2.687-3.965 5.784-6.062 10.144-8.732.046-1.998-3.195-5.336-4.57-5.463-10.173 5.7-10.493 17.766-11.47 26.518z"
        style={{
          display: "inline",
          fill: "#f4d7d7",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.2,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <text
        xmlSpace="preserve"
        x={14.839}
        y={236.718}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={14.839}
          y={236.718}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "8.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"muela de juicio"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={170.166}
        y={237.81}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={170.166}
          y={237.81}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "8.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"muela de juicio"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={96.151}
        y={171.645}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={96.151}
          y={171.645}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "13.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"Paladar"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={18.877}
        y={130.918}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={18.877}
          y={130.918}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "8.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"derecha"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={187.709}
        y={128.513}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={187.709}
          y={128.513}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "8.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"izquierda"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={236.232}
        y={46.483}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={236.232}
          y={46.483}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "8.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"muela de juicio"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={391.586}
        y={45.067}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={391.586}
          y={45.067}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "8.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"muela de juicio"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={321.197}
        y={147.109}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "condensed",
          fontSize: "6.25px",
          lineHeight: "125%",
          fontFamily: "Arial",
          InkscapeFontSpecification: "&quot",
          textAlign: "start",
          letterSpacing: 0,
          wordSpacing: 0,
          writingMode: "lr-tb",
          textAnchor: "start",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={321.197}
          y={147.109}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "condensed",
            fontSize: "13.75px",
            fontFamily: "Arial",
            InkscapeFontSpecification: "&quot",
          }}
        >
          {"Lengua"}
        </tspan>
      </text>
      <path
        d="m339.615 34.437.448 99.312M340.287 153.029l-.224 97.518M115.435 179.594l.112 65.124M115.817 33.256l-.476 121.584"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M219.375-312.974h3.514v12.045h-3.514z"
        style={{
          display: "inline",
          opacity: 1,
        }}
        transform="matrix(.12171 .99257 -.99937 .03539 0 0)"
      />
      <path
        d="M220.113-326.246h3.511v11.396h-3.511z"
        style={{
          display: "inline",
          opacity: 1,
        }}
        transform="matrix(.11524 .99334 -.9993 .0374 0 0)"
      />
      <path
        d="M352.353-171.764h3.35v12.942h-3.35z"
        style={{
          display: "inline",
          opacity: 1,
        }}
        transform="matrix(.59603 .80296 -.87932 .47623 0 0)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M277.199 51.93c-1.466.001-3.156.137-4.66.733-2.884.97-4.069 3.12-5.84 9.466-1.999 4.688-1.363 11.407-.394 14.892 1.077 3.227 1.161 5.17 5.233 8.088 1.585.604 4.635.787 6.283.247 3.701-1.214 7.394-3.75 8.413-5.885 2.615-3.467-.648-9.975-1.522-14.08.567-1.938 2.228-9.005-.132-10.812-2.642-2.419-4.94-2.652-7.381-2.65zm.002 1.009c3.63.303 5.381 1.178 6.698 2.508 1.823 3.415.009 7.236-.116 10.051 1.416 5.642 3.28 10.46 1.97 12.402-1.937 2.868-4.3 4.72-8.08 6.408-1.574.507-4.471.311-5.985-.256-3.271-2.922-2.964-4.57-4.34-7.769-.765-3.778-1.215-9.044.579-13.802 1.122-2.976 1.984-7.572 5.06-8.742 1.353-.437 3.071-.896 4.214-.8zm2.447 2.037c-1.203.11-1.469 1.329-2.675 1.476-.636-.292-2.634-1.372-3.256-.802.135 2.045 1.484.468 2.495 1.956-.698 1.418-.753 2.72-.1 4.785.32 1.351 1.046 2.016 1.278 3.389.279.278-1.06 4.217-1.255 4.698-.902.458-1.455.757-3.021.985-.719-.166-1.811-.92-2.206-.498-.097.549.694 1.242 1.24 1.754.06.831-.89.757-.916 2.273 1.767.228 1.35-2.151 2.445-2.287 1.729-1.075 2.226-1.039 3.059-.768.52 2.248.843 4.008.637 6.481.088.507-2.519 2.11-1.425 2.552 1 .405 1.72-1.575 2.328-1.802.416-.155 2.22 1.502 2.83 1.12.402-1.332-2.395-1.84-2.405-2.174-.083-2.833-.907-4.906-1.225-7.534-.196-1.522.902-3.821 1.013-4.277.5-.26 4.563.255 4.522-.855-.356-.668-2.327-.182-4.115-.32-.819-.547-1.324-2.244-1.804-3.185-.611-3.18.355-3.758.432-4.29.828-1.23 2.795-1.344 2.442-2.644-.115-.02-.221-.042-.318-.033z"
        style={{
          display: "inline",
          fillRule: "evenodd",
          strokeWidth: "1.20469999pt",
        }}
      />
      <path
        d="M276.643 53.066c-3.152-.093-6.255 1.845-7.058 4.995-2.388 5.53-3.712 11.75-2.162 17.697.83 3.038 1.906 6.456 4.557 8.37 2.96.567 6.214.43 8.701-1.487 2.161-1.465 4.513-3.262 5.41-5.79.157-4.204-2.055-8.082-2.179-12.229.31-2.894 1.24-6.106.023-8.89-1.652-2.06-4.792-2.618-7.292-2.666zm3.001 1.878c1.02.137-.217 1.372-.62 1.565-3.024 1.692-2.431 6.22-.19 8.271 1.233.57 3.127-.213 4.062.776-.961 1.231-3.067.489-4.402.762-1.221 2.46-.947 5.173-.321 7.748.56 1.395.055 3.653 1.485 4.572.55.11 2.054 1.196 1.233 1.61-1.152.032-2.686-1.835-3.396-.161-.226.511-1.79 1.531-1.813.423.877-1.206 1.977-2.417 1.544-4.06-.323-1.414.32-3.584-1.035-4.55-1.81-.127-3.22 1.314-3.97 2.814-.426.757-1.442.445-.877-.465.37-.731 1.163-1.692.027-2.237-.564-.28-.686-1.39.188-1.08 1.886.649 4.875.293 5.104-2.167.989-2.128.37-4.396-.563-6.41-.663-1.657-.25-3.467-.17-5.168-.644-.161-2.484-.528-2.033-1.551 1.614-.115 3.519 1.253 4.844-.288.28-.167.572-.363.903-.404z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.06340761,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
    </g>
  </svg>
)
export default FondoSvg
