import React from 'react';
import axios2 from 'services/axios2';
import { ToastContainer, toast } from 'react-toastify';
import NuDropdown from "../../components/nubox/NuDropdown";
import ImageUploadSingle from "components/nubox/ImageUploadSingle";

import { withRouterJim } from "router/withRouterJim";

import { json, Link } from "react-router-dom";
//import '../App.css';
import logo from 'assets/logo.png';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";

const initialForm={
  idd:0,
  ope:'n',
  nomape:'',
  username:'',
  id_rol:0,
  foto_path1:'',
}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class MiPerfil extends React.Component {

  myRef = React.createRef();//create ref
  

  state = {
    cargando:false,
    form: initialForm,
    data: [],/* lista  */
    roles: [],
  };

  //-----------------------------------------------

  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({ data: [] });

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');

    //this.setState({fechai: defaultValue}); 
    this.setState({ form: { ...this.state.form, fecha: defaultValue, } });

    this.cargar_rol();
    this.cargar_datos();

    //this.cargar_citas();
    //this.fetchData(this.state.fechai);


  }
  cargar_datos = () => {
    const user = this.props.store.user;
    let param = { 
      id_u: user.id_u,
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "perfil_get_user", param)
    .then(res => {
      //console.log(res.data);

        if(res.data !== null ){ 
            //NotificationManager.success("Correcto");
            console.log(res.data);
            this.setState({form: res.data});
            //console.log(this.state.medicos);
        }else{
            this.setState({form: initialForm});
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //----------------------------------
  //----cargamos los datos desde la base de datos
  cargar_rol = async() => {
    let param = { 
    }
    
    await axios2.post(process.env.REACT_APP_API_URL  + "cbo_rol", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({roles: res.data});
            //console.log(this.state.roles);
            
        }else{
          toast.error("No existen datos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  onSubmitHandler= (event)=>{
    event.preventDefault();

    this.save();
  }
  //-----------------------------------
  formSave =() =>{
    const user = this.props.store.user;
    //const id_paciente = this.props.store.paciente.id_paciente;

    let param = {
                ...this.state.form,
                id_u: user.id_u,
                };
    console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "perfil_store", param)
    .then(res => {
        console.log(res.data);
        if(res.data.exito === 1){ 
            toast.success(res.data.mensaje);            
        }else{
            toast.error(res.data.mensaje);
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
    
  }
  //-------------------------------------
  render() {

    return (
      <div>
        <ToastContainer />
        
        <div className="container pt-3">

        {  JSON.stringify( this.state.form) 
         }

          <h4 style={{color:'grey'}}><i className="fa fa-hand-up"></i>Datos de perfil</h4>

          <form>
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <div card="" className="card portlet light profile-sidebar-portlet bordered">
                  <div className="card-body">
                    <div className="form-group">
                      <label className="control-label">Foto</label>
                      <ImageUploadSingle  
                        okImage={ this.okImage}
                        cancelImage={ this.cancelImage}
                        imageURL={process.env.REACT_APP_AFILES}
                        fileName={this.state.form.foto_path1}
                        withImage="150px"
                      />
                  </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-sm-12">
                <div className="form-group">
                  <label>Nombre:</label>
                  <div>
                    
                    <input
                        type='text'
                        className='form-control'
                        style={{ width: "160px"}}
                        name='nomape'
                        value={this.state.form.nomape}
                        onChange={this.handleChangeForm}  />
                  </div>
                </div>
               
                <div className="form-group">
                  <label>username:</label>
                  <div>
                      <input
                          type='text'
                          className='form-control'
                          style={{ width: "160px"}}
                          name='username'
                          value={this.state.form.username}
                          onChange={this.handleChangeForm}  />
                  </div>
                </div>
                <div className="form-group">
                  <label>email:</label>
                  <div>
                      <input
                          type='text'
                          className='form-control'
                          style={{ width: "160px"}}
                          name='email'
                          value={this.state.form.email}
                          onChange={this.handleChangeForm}  />
                  </div>
                </div>
                <div>-----------Datos de sesion---------</div>
                <div className="form-group">
                  <label>Rol:</label>
                  <div>
                    
                    <NuDropdown
                        options={this.state.roles}
                        onChange={this.handleChangeForm}
                        value={this.state.form.id_rol}
                        name="id_rol"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Caja:</label>
                  <div>
                    <input
                          type='text'
                          className='form-control'
                          style={{ width: "160px"}}
                          name='id_ca'
                          value={this.state.form.id_ca}
                          onChange={this.handleChangeForm}
                          readOnly  />
                      Caja abierta:0
                  </div>
                </div>
               

              </div>
            </div>
            <div className="row row justify-content-end">
              <div className="col-lg-9 col-sm-12">
                {/*<button type="submit" className="btn btn-primary btn-sm">Guardar</button>*/}
                <Button color="primary" onClick={ this.formSave}>Guardar</Button>{' '}
                </div>
            </div>
          </form>





        </div>

      </div>
    );
  }
}

export default withRouterJim(MiPerfil);