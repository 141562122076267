import React from 'react';
//import ReactDOM from 'react-dom/client';
import {createRoot } from 'react-dom/client';
import App from './App';

//--- bootstrap full 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
//---------------

import 'font-awesome/css/font-awesome.min.css';
import 'react-notifications/lib/notifications.css';

import 'react-calendar/dist/Calendar.css';

import './assets/_preclinic/assets/css/style.css';
import './css/bootstrapnubox.css';
import './css/lte2_ex.css';
import './assets/css/tabla_basic.css';
import './assets/css/tabla_fixed.css';
import './css/tabla_plomo.css';
import './css/ventanas2.css';

//-- toast
import "react-toastify/dist/ReactToastify.css";

//---- uso de CONTEXT API 
import StoreProvider from './store/StoreProvider'; 

// browser router
import {BrowserRouter} from 'react-router-dom';

const container = document.getElementById('root');
const root= createRoot(container);
root.render(
  <StoreProvider >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
);

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreProvider >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
);*/


