import React, { useRef, useEffect, useState } from "react";

const CirculoCaries = (props) => {

  const [cade_temp, setCadeTemp] = useState(props.cade);
  //--------valores iniciales  fill="#d1d9de"
  let color0= 'silver';
  let color1= 'silver';
  let color2= 'silver';
  let color3= 'silver';
  let color4= 'silver';
  //"#d1d9de"
  console.log('cade_temp:'+ cade_temp);
  //---si es '0', ocultamos la muestra 
  if(cade_temp=='00000' || cade_temp==''){
    //elemSvg.getElementById( piezax +"_0").setAttribute("style", "fill:silver");
      return;
  }
  //de lo contrario calculamos quienes estan pintados
  const myArray = cade_temp.split("");

  //--ahora cada elemento, lo recorremos 
  for (let i = 0; i < myArray.length ; i++) {
    let valor = myArray[i];   
    //console.log(valor+"-");
    //ahora si lo cambiamos de color
    if(i==0){ //top
      if(valor==='1'){  color0 = 'red'; }
    }
    if(i==1){ //botton
      if(valor==='1'){  color1 = 'red'; }
    }
    if(i==2){ //izq
      if(valor==='1'){  color2 = 'red'; }
    }
    if(i==3){ //der
      if(valor==='1'){  color3 = 'red'; }
    }
    if(i==4){ //centro
      if(valor==='1'){  color4 = 'red'; }
    }
  }
  
  //-------------agregamos el item con su informacion para propositos futuros
  const onClick0 = (e) => {
    // 👇️ filter with 1 condition
    //console.log(e.target.value);
    //console.log("click0");
    let myArray = cade_temp.split(""); //convertimos en array
    //console.log("->"+myArray[0]);
    if(myArray[0]==='0'){
      myArray[0]='1';
    }else{
      myArray[0]='0';
    }
    //console.log(myArray.join(''));
    let new_cade= myArray.join('');
    setCadeTemp(new_cade);
    //--lo convertimos a cadena

    //props.onChange(e);
  }
  //-------------------------------------
  const onClick1 = (e) => {
    let myArray = cade_temp.split(""); //convertimos en array
    //console.log("->"+myArray[0]);
    if(myArray[1]==='0'){
      myArray[1]='1';
    }else{
      myArray[1]='0';
    }
    let new_cade= myArray.join('');
    setCadeTemp(new_cade);
    
    //props.onChange(e);
  }
  //-------------------------------------
  const onClick2 = (e) => {
    let myArray = cade_temp.split(""); //convertimos en array
    //console.log("->"+myArray[0]);
    if(myArray[2]==='0'){
      myArray[2]='1';
    }else{
      myArray[2]='0';
    }
    let new_cade= myArray.join('');
    setCadeTemp(new_cade);
    
    //props.onChange(e);
  }
  //-------------------------------------
  const onClick3 = (e) => {
    let myArray = cade_temp.split(""); //convertimos en array
    //console.log("->"+myArray[0]);
    if(myArray[3]==='0'){
      myArray[3]='1';
    }else{
      myArray[3]='0';
    }
    let new_cade= myArray.join('');
    setCadeTemp(new_cade);
    
    //props.onChange(e);
  }
  //-------------------------------------
  const onClick4 = (e) => {
    let myArray = cade_temp.split(""); //convertimos en array
    //console.log("->"+myArray[0]);
    if(myArray[4]==='0'){
      myArray[4]='1';
    }else{
      myArray[4]='0';
    }
    let new_cade= myArray.join('');
    setCadeTemp(new_cade);
    
    //props.onChange(e);
  }
  return(
  <svg style={{ width: "inherit", height: "inherit" }}
         viewBox="0 0 6.5 6.5"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
      >
    
      <path
        id="m0_0"
        fill={color0}
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="0.053"
        d="m2.123 1.735-.67-.68s.96-.798 2.014-.657c.949.05 1.667.696 1.667.696l-.708.697c-.75-.564-1.615-.501-2.303-.056Z"
        display="inline"
        onClick={onClick0}
      ></path>
      <path
        id="m0_3"
        fill={color3}
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="0.053"
        d="m4.943 2.302.7-.652s.638.853.529 1.996C6.069 4.7 5.506 5.23 5.506 5.23l-.729-.64c.903-1.14.166-2.288.166-2.288Z"
        display="inline"
        onClick={onClick3}
      ></path>
      <path
        id="m0_4"
        fill={color4}
        stroke="#000"
        strokeWidth="0.064"
        d="M2.827 4.59a1.34 1.34 0 0 1-.708-.64c-.121-.223-.148-.33-.146-.6.002-.388.092-.623.344-.902.422-.467 1.12-.548 1.674-.194.847.541.829 1.696-.034 2.23-.292.18-.802.228-1.13.106Z"
        display="inline"
        onClick={onClick4}
      ></path>
      <path
        id="m0_1"
        fill={color1}
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="0.053"
        d="m4.257 5.04.705.75s-.862.655-1.838.562c-.95-.004-1.575-.555-1.575-.555l.698-.78c.662.456 1.465.324 2.01.022z"
        display="inline"
        onClick={onClick1}
      ></path>
   
    <path
      id="m0_2"
      fill={color2}
      fillOpacity="0.549"
      fillRule="evenodd"
      stroke="#000"
      strokeDasharray="none"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="4"
      strokeOpacity="1"
      strokeWidth="0.053"
      d="m1.657 4.518-.666.665S.325 4.39.384 3.348C.354 2.399.88 1.666.88 1.666l.703.675c-.687 1.159.073 2.177.073 2.177z"
      display="inline"
      onClick={onClick2}
    ></path>
  </svg>
  )
}
export default CirculoCaries;