import * as React from "react";

const Diente18 = (props) => (
  <svg style={{ width: "inherit", height: "inherit" }}
          version="1.1"
          viewBox="0 0 31.921 36.45"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
      >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M9.38.2c1.466.001 4.109.697 5.444 1.573 2.717 2.034 5.134 4.128 6.906 9.354 1.157 5.08-1.832 10.79-2.8 14.275-1.078 3.227-.546 6.291-3.216 7.472-1.586.604-4.523.451-7.124.19-1.964-.653-5.264-3.414-6.956-5.324-2.615-3.467.143-10.03 1.017-14.136-.566-1.937-2.284-9.565.076-11.372C5.37-.187 6.938.197 9.38.2m-.002 1.008c-3.63.304-4.653.562-5.97 1.892-1.822 3.415.047 7.796.172 10.611C2.164 19.354.717 24.29 2.115 26.17c2.217 2.98 4.65 5.16 6.734 5.624 2.678.438 5.145.535 6.659-.032 1.533-.96 1.394-3.785 2.378-7.096.765-3.779 3.512-8.316 2.616-13.186-.577-3.128-3.89-6.395-6.125-8.63-1.018-.83-3.856-1.737-4.999-1.641M8.22 6.104c.81.167 1.665 1.496 2.87 1.644.805-.04 2.243-.812 2.949-.354-.5 1.064-1.849.468-2.383 1.34-.283.661-.115 1.374-.292 2.543-.32 1.351-1.018 2.716-1.278 4.341-.055.615-.233 4.674.073 5.463.64.594 4.073-.433 4.161.274-.064.668-3.65.36-4.246 1.067-.521 2.247-.234 2.464.197 2.64.475.195 2.17.92 2.041 1.375-.143.51-1.803-.426-2.412-.653-.808-.379-2.697.277-2.858-.253-.223-.729 1.891-.439 1.901-.773.042-1.417.258-2.719.187-3.192-.076-.497-2.143.084-2.135-.452.01-.59 1.784-.343 2.17-.891.196-1.522.157-3.57.046-4.025-.5-.26-4.002.143-3.962-.967.356-.668 1.767-.07 3.554-.208.82-.548 1.1-2.468 1.58-3.41.612-3.18.038-2.3-.04-2.833-.827-1.229-2.57-1.344-2.44-2.447.013-.116.317-.131.317-.23"
      clipRule="evenodd"
    />
    <path
      fill="#F9F9F9"
      stroke="#000"
      strokeWidth={0.011}
      d="M9.382 1.219c-1.351.08-2.721.197-4.021.593-.874.32-1.781.854-2.177 1.736-.693 1.528-.597 3.252-.457 4.881.212 1.769.702 3.503.835 5.276-.677 2.822-1.506 5.62-1.906 8.5-.1 1.076-.21 2.199.099 3.252.403 1.029 1.231 1.81 1.923 2.647 1.337 1.457 2.827 2.917 4.736 3.575 1.935.46 3.952.568 5.93.377.645-.077 1.358-.241 1.734-.828.687-.963.815-2.184 1.08-3.306.526-2.84 1.372-5.603 2.309-8.33.65-1.948 1.234-3.963 1.185-6.037.027-1.146-.068-2.314-.532-3.376-1.01-2.448-2.882-4.389-4.672-6.285-.659-.67-1.323-1.384-2.223-1.725-1.212-.517-2.51-.972-3.843-.95Zm-1.12 4.957c.579.095.957.627 1.439.922.548.389 1.229.846 1.927.564.717-.151 1.446-.493 2.19-.345.154.006.235.165.086.26-.537.708-1.65.395-2.19 1.083-.33.6-.227 1.33-.314 1.987-.111 1.548-.942 2.928-1.215 4.447-.258 1.535-.215 3.104-.194 4.656.05.441-.017.931.182 1.338.38.278.904.15 1.345.166.804-.007 1.607-.182 2.409-.12.172.007.481.194.28.37-.606.288-1.314.26-1.967.37-.675.113-1.41.092-2.026.423-.32.285-.26.783-.355 1.169-.022.455-.177.999.111 1.397.557.433 1.287.582 1.853 1.01.172.148.507.308.457.58-.346.226-.783-.033-1.126-.147-.746-.292-1.48-.748-2.316-.651-.56-.006-1.157.172-1.7-.025-.197-.196.023-.476.254-.501.476-.16 1.01-.11 1.476-.274.176-.514.116-1.09.19-1.628.014-.582.146-1.18.038-1.755-.183-.178-.512-.08-.745-.132-.423-.047-.881.035-1.28-.123-.21-.24.217-.386.391-.436.538-.165 1.15-.15 1.63-.466.222-.597.155-1.268.199-1.896-.02-.725.073-1.47-.082-2.183-.152-.213-.498-.075-.719-.145-.926-.09-1.91-.006-2.786-.332-.231-.09-.611-.408-.32-.644.3-.263.756-.092 1.12-.137.77.008 1.543.072 2.309.084.829-.814 1-2.027 1.427-3.06.306-.817.4-1.707.422-2.574 0-.394-.358-.652-.554-.96-.611-.692-1.618-.935-2.132-1.717-.13-.201-.088-.538.196-.564q.045-.01.09-.01Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={0.011}
      d="M9.612 1.215c-1.497.06-3.023.174-4.448.666C4.195 2.243 3.286 2.954 3 3.988c-.526 1.631-.425 3.39-.19 5.064.213 1.525.63 3.029.71 4.567-.363 1.954-.952 3.863-1.363 5.81-.399 1.837-.783 3.74-.507 5.623.263 1.222 1.235 2.107 2.001 3.034 1.318 1.434 2.778 2.865 4.635 3.56 1.09.29 2.223.426 3.35.487 1.238-.023 2.53.118 3.718-.311.87-.379 1.195-1.376 1.43-2.22.535-2.279.943-4.59 1.666-6.82.748-2.552 1.797-5.042 2.148-7.695.059-1.15.095-2.314-.044-3.46-.317-1.758-1.336-3.289-2.384-4.697-1.226-1.503-2.542-2.943-3.993-4.233-.785-.574-1.741-.86-2.657-1.156-.621-.173-1.258-.339-1.908-.326ZM8.226 6.202c.705.143 1.168.774 1.777 1.116.472.326 1.066.561 1.636.353.708-.146 1.44-.482 2.169-.334.226.04.089.305-.05.38-.605.51-1.618.291-2.081 1.005-.35.84-.187 1.793-.405 2.662-.403 1.69-1.215 3.298-1.252 5.067-.062 1.461-.078 2.932.05 4.39.11.49.74.426 1.123.437.92.004 1.838-.2 2.754-.116.155.008.459.242.199.344-1.224.443-2.592.27-3.816.724-.44.2-.368.772-.466 1.168-.02.468-.209 1.032.1 1.44.685.575 1.666.737 2.263 1.429.138.235-.252.321-.41.249-.754-.207-1.447-.608-2.186-.844-.814-.022-1.644.04-2.451-.038-.255-.21.05-.474.277-.5.46-.158.992-.095 1.424-.314.183-.454.097-.98.177-1.46.027-.626.136-1.272.03-1.892-.256-.212-.65-.1-.96-.141-.357-.033-.784.033-1.09-.175.017-.337.47-.348.715-.425.46-.141 1.065-.107 1.388-.52.173-.508.079-1.073.13-1.602-.013-.778.094-1.572-.048-2.34-.13-.24-.488-.106-.708-.174-.942-.106-1.928-.033-2.826-.37-.188-.088-.538-.35-.327-.573.44-.306 1.02-.097 1.516-.141.663.011 1.334.057 1.993.047.748-.904 1.02-2.078 1.405-3.16.256-.877.465-1.808.347-2.721-.44-.85-1.305-1.332-2.059-1.858-.274-.241-.717-.543-.604-.962a.26.26 0 0 1 .266-.15Z"
    />
  </svg>
);
export default Diente18;