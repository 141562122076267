import React from "react";

import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NuDropdown from "components/nubox/NuDropdown";


import Child from "views/cita_all/Child";
import CitaModal from "views/cita_all/CitaModal";
import PacienteModal from "views/_box/PacienteModal";

//import listac from '../assets/img/lista2.png';
import confirmService from 'components/confirmService'; 
import cUtilFecha from 'helpers/cUtilFecha'

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
];
const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const data = [
  { id_cita: 1, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
  { id_cita: 2, id_citaho:0, hora: "00:00", hora_ini: "00:30", historia:'V-415', paciente:"", motivo:"", atendido_sn:"", id_estadoc:""},
 
];

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Citas extends React.Component {
  
  myRef = React.createRef();//create ref
  citaRef = React.createRef();//create ref
  paciRef = React.createRef();//create ref

  state = {
    data: data,/* lista de citas */
    modalActualizar: false,
    modalInsertar: false,
    form: {
      id_medico: 0,
      fecha: "",
    },
    form01: {
      idd:0,
      ope:'',
      id_cita:0,
      id_medico: 0,      
      fecha: null,
      id_hora:0,
      id_paciente:0,
      motivo:'',
    },
    medicos:[],
    citas:[],
    horas:[],
  };
  
  //-----------------------------------------------
 
  //------------------------------------------------------
  componentDidMount() {
    console.log("componentDidMount citas");
    //--limpiamos el array de prueba
    this.setState({data:[]});

    
    const fechaActual = cUtilFecha.fechaActualStr();
  
    //this.setState({fechai: defaultValue}); 
    this.setState({ form:{...this.state.form, fecha: fechaActual,}}); 

    this.cargar_medicos();
    this.cargar_horas();
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
    
    
  }

  //------------ cambiamos la fecha
  /*handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
  }*/
  //----------------especial, actualiza valores del form -------------------------------
  handleChange = (e) => {
    //requerimos la prop name="?" en cada control
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleChangeForm01 = (e) => {
    //requerimos la prop name="?" en cada control
    //console.log(e.target.value);
    this.setState({
      form01: {
        ...this.state.form01,
        [e.target.name]: e.target.value,
      },
    });

  };
  //---------------- del autocomplete --------
  handleOnSelect = (item) => {
    // the item selected
    console.log("handleOnSelect");
    console.log(item)
  }
  //======================================================
  modalOpen = () => {
    //setModalOpen(true);
    
    
    this.setState({ modalInsertar: true});
  }
  modalClose = () => {
    //setModalOpen(true);
    this.setState({ modalInsertar: false});
  }
  //----cargamos los datos desde la base de datos
  cargar_medicos = () => {
    let param = { 
    }
    
    axios2.post("cbo_medicos", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            //---lega un array en este formato:
            //-- { value: 1, label: "Dr. Gerson Abner Villafuerte Oporto" }
            this.setState({medicos: res.data});

            //-- esrtablecemos la primera opcion como la elegida
            this.setState({
              form: {
                ...this.state.form,
                id_medico: res.data[0].value,
              },
            });
        }else{
            NotificationManager.error("No existen medicos");
        }

    }).catch(error => {
        alert("---- Existio un error ---");
        console.log(error);
        
    });
  };
  cargar_horas = () => {
    let param = { 
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "get_list_cita_hora", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            //-- formato value, label
            this.setState({horas: res.data});
            //console.log(this.state.horas);
        }else{
            NotificationManager.error("No existen medicos");
        }

    }).catch(error => {
        NotificationManager.error("Bad Request");
        console.log(error);
        
    });
  };
  cargar_citas = () => {
    let param = { 
    }
    
    axios2.post(process.env.REACT_APP_API_URL  + "get_list_cita_hora", param).then(res => {
        if(res.data.length>0){ 
            //NotificationManager.success("Correcto");
            this.setState({citas: res.data});
        }else{
          this.setState({citas: []});
        }

    }).catch(error => {
        alert("Bad Request");
        console.log(error);
        
    });
  };
  //----cargamos los datos desde la base de datos
  fetchData = (fechai, id_medico) => {
    let param = { 
      id_espe:1,
      id_medico: id_medico,
      fechai: fechai,
    }
    //console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "cita_get_lista", param).then(res => {
        if(res.data.length>0){ 
            
            this.setState({ data: res.data});
            
        }else{
            this.setState({ data: []});
            toast.error("No existen registros", {position: toast.POSITION.TOP_LEFT});
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        alert("Bad Request");
        console.log(error);
        
    });
  };
 
  

  nueva_cita = () => {
    return 0;
  };

  nuevo = id_citaho => {
    console.log("nuevo - myRef -");
    //console.log(this.myRef.current);

    //this.myRef.current.editar(1, 'e');
    //this.myRef.current.showModal();
    //this.myRef.current.showAlert();
    //this.citaRef.current.editar(1, 'e');
    
    let id_medico = this.state.form.id_medico;
    let fecha = this.state.form.fecha;
    console.log("->" + id_medico);
    console.log("->" + fecha);
    
    
    //this.citaRef.current.set_id_medico( id_medico );
    //this.citaRef.current.set_fecha( fecha );
    //this.citaRef.current.set_id_citaho( id_citaho );

    this.citaRef.current.nuevo(id_medico, fecha,  id_citaho);

    //this.modalOpen();
  };
  /*{
  "id_cita": 86,
  "id_citaho": 11,
  "hora": "9:30",
  "historia": 0,
  "paciente": "SDFSD",
  "motivo": "sdf",
  "obs": "",
  "atendido_sn": 1,
  "id_estadoc": 0
  } */
  editar = (item) =>{
    console.log("editar");
    //    console.log(item);
    let idd = item.id_cita;
    this.citaRef.current.editar(idd, 'e');
  }
  eliminar = (item) =>{
    console.log("eliminar");
    console.log(item);
    //--------------------------------
    confirmService.show({
      message: 'Esta seguro de eliminar?',
      title: 'Confirmar',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
        let idd = item.id_cita;
        this.citaRef.current.eliminar(idd);
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })

  }
  
  //---clic en el boton para buscar los elementos
  buscar_click=(e)=>{
    //console.log(this.state.fechai);
    if(this.state.fechai==""){
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
      
    }

    this.fetchData(this.state.form.fecha, this.state.form.id_medico);
    
  }
  
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }

  //-------------------------------------
 

  citaOkModal =()=>{
    console.log("citaOkModal");
    
    this.fetchData(this.state.form.fecha, this.state.form.id_medico);
  }
  citaCancelModal =()=>{
    console.log("citaCancelModal");
  }
  citaNuevoPaciente =()=>{
    console.log("citaNuevoPaciente");
    this.paciRef.current.nuevo();
  }
  //-------------------------------------
  paciOkModal =(id_paciente)=>{
    console.log("paciOkModal");
    //toast.success("Guardado con éxito", {position: toast.POSITION.TOP_LEFT});
    
  }
  paciCancelModal =()=>{
    console.log("paciCancelModal");
  }
  //-------------------------------------
  render() {
    
    return (
      <div>

      <div className="content">
        <ToastContainer />
        {
          this.state.medicos.map((element)=>{
            <div className="titulo-noticia">{element}</div>
          })
        }
        <section className="content-header">
          <h1>
            <i className="fa fa-calendar"></i> Citas
          </h1>
        </section>

        <div className="row">
          <div className="col-md-12">
            <div className="box">
              <div className="box-header">

              </div>
              {/* /.box-header */}
              <div className="box-body">
                { this.state.form.id_medico}
                <br/>
                { this.state.form.fecha}
                <form   method='POST' className="form-horizontal">

                  <div className="row">
                    <div className="col-md-8">

                      <div className="form-group row">
                        <label className="col-md-3 col-form-label form-control-label">Médico</label>
                        <div className="col-md-9">
                          <NuDropdown
                            options={this.state.medicos}
                            onChange={this.handleChange}
                            value={this.state.form.id_medico}
                            name="id_medico"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 col-form-label form-control-label">Fecha</label>
                        <div className="col-md-9">
                          <input type="date" className="form-control" name="fecha" value={this.state.form.fecha} onChange={ this.handleChange}/>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-12">
                          <button type="button" className="btn btn-md btn-primary"  onClick= { this.buscar_click}>
                            <i className="fa fa-search"></i> Buscar
                          </button>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-4" id="totales">
                      <label style={{ marginTop:"50px", fontSize: "14px" }} className="control-label">Total Ocupados: 
                        <span  id="total-ocupados"></span></label> 
                      <br/>

                      <label style={{ fontSize: "14px" }} className="control-label">Total Disponibles: 
                        <span id="total-disponibles"></span>
                      </label>
                    </div>
                  </div>
                </form>

                <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-info titulosBusqueda hidden" role="alert">

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive"> 

                        <table id="tabla01" className="table table-striped table-bordered table-condensed table-hover"
                          width="100%" cellSpacing="0" cellPadding="0">
                          <thead>
                            <tr className="btn-primary">
                              <th style={{ textAlign: "center" }}>Hora</th>
                              <th style={{ textAlign: "center" }}>Historia</th>
                              <th style={{ textAlign: "center" }}>Paciente</th>
                              <th style={{ textAlign: "center" }}>Motivo</th>
                              <th style={{ textAlign: "center" }}>Observación</th>
                              <th style={{ textAlign: "center" }}>Atendido</th>
                              <th> Accción </th>
                            </tr>
                          </thead>
                          <tbody>

                            {this.state.data.map((item) => (
                              <tr>
                                <td>{ item.hora }
                                { item.id_cita === 0 &&
                                <Button className="btn btn-sm btn-success float-right" onClick={()=> this.nuevo(item.id_citaho)}><i className='fa fa-plus'></i></Button>
                                }
                                </td>
                                <td>{item.historia}
                                
                                </td>
                                <td>{item.paciente}</td>
                                <td>{item.motivo}</td>
                                <td>{item.obs}</td>
                                <td className='text-center'></td>
                                <td>{
                                  item.id_cita!==0 ?
                                  <div>
                                    <button className='btn btn-warning btn-sm' onClick={ ()=> this.editar(item) } > 
                                        <i className='fa fa-pencil'></i> 
                                    </button> 
                                    &nbsp;
                                    <button className='btn btn-danger btn-sm' onClick={ ()=> this.eliminar(item) } > 
                                        <i className='fa fa-trash'></i> 
                                    </button>
                                  </div>
                                  : <></>
                                  
                                }</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box -->*/}
          </div>
          {/*<!-- /.col -->*/}
        </div>



      

      </div>
      
      <Child ref={this.myRef} />
      <CitaModal 
        ref={this.citaRef}
        okModal={this.citaOkModal}
        cancelModal={this.citaCancelModal}
        onNuevoPaciente={this.citaNuevoPaciente}
        value = {this.state.form01.id_cita}
      />
      
      <PacienteModal 
        ref={this.paciRef }
        okModal={this.paciOkModal }
        cancelModal={this.paciCancelModal }
        value = {this.state.form01.id_paciente }
      />

      {/*--- modal---- */}
      
      </div>
    );
  }
}
  
  //------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
export default withRouter(Citas);