import { React, Component } from "react";

const MySvg = (props) => {
  return (
      // With Styling
      <svg style={{ width: "inherit", height: "inherit" }}
          version="1.1"
          viewBox="0 0 31.921 36.45"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
      >
          <g transform="translate(-20.655 -17.456)">
              <path
                  d="m51.254 44.131-14.638 8.451-14.638-8.451v-16.902l14.638-8.451 
          14.638 8.451z"
                  fill="none"
                  stroke="#fb00ff"
                  stroke-linejoin="round"
                  stroke-width="2.6458"
              />
          </g>
      </svg>
  );
}

export default MySvg