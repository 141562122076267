import React, { Component } from 'react';
import { render } from 'react-dom';

// After- despues
import { createRoot } from 'react-dom/client';

import './styles.css'

let resolve;
const defaultProps = {
  title: 'Confirmation',
  message: 'Are you sure?'
};

class Confirm extends Component {



  static create(props = {}) {
    
    /*const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<Confirm createConfirmProps={props} />, containerElement);*/

    //---para react 18-----
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    const root = createRoot(containerElement); // createRoot(container!) if you use TypeScript
    root.render(<Confirm createConfirmProps={props} />);

  }

  constructor() {
    super();
    

    this.state = {
      isOpen: false,
      showConfirmProps: {},
    };

    

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    //resolve(false);
    //this.props.reject(); /* callback */
    if (this.state.showConfirmProps.reject) {
      this.state.showConfirmProps.reject();
    }    
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    //resolve(true);
    //this.props.accept(); /* callback */
    
    if (this.state.showConfirmProps.accept) {
      this.state.showConfirmProps.accept({ data: 'val' });
    }    
  }

  show(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, showConfirmProps });

    //console.log(this.state.showConfirmProps);
    
    return new Promise((res) => {
      resolve = res;
    });
  }
  /******************************************************************************************** */
  render() {
    const { isOpen, showConfirmProps } = this.state;
    const { message, title, icon, acceptLabel, rejectLabel,classHeader, classButton, ...rest } = showConfirmProps;

    const headerCls = `modal-header1 ${classHeader}`;
    const footerBtnCls = ` ${classButton}`; 
    return (
      <>
      <div className="modal-backdrop fade1 in" style={{display: this.state.isOpen ? 'block' : 'none'}}></div>
      {/* */}
      <div className='modal fade1 in' tabIndex="-1" role="dialog" style={{display: this.state.isOpen ? 'block' : 'none'}} >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content1">
            
            <div className={headerCls}>         {/*"modal-header1 bg-default text-default">*/}
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCancel}>
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">{title || defaultProps.title}</h4>
            </div>
            <div className="modal-body1">
              <i className= {icon || defaultProps.icon} ></i>
              {message || defaultProps.message}
            </div>
            <div className="modal-footer1">
              
              
              <button className={footerBtnCls} onClick={this.handleConfirm}>{ acceptLabel || defaultProps.acceptLabel }</button>
              &nbsp;
              <button className="btn btn-default" onClick={this.handleCancel}>{ rejectLabel || defaultProps.rejectLabel }</button>
            </div>
          </div>
        </div>
      </div>
        

      
      </>
    );
  }
}

export default Confirm;
