import * as React from "react";

const Diente13 = (props) => (
  <svg style={{ width: "inherit", height: "inherit" }}
          version="1.1"
          viewBox="0 0 18.420341 28.245827"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          {...props}
      >
   
   
   <path
      fill="#000"
      fillRule="evenodd"
      d="M10.115.08C7.722.669 4.224 4.796 3.719 6.18c-.504 1.383-.84 2.457-1.591 5.325-.9 2.684-2.104 6.044-2.06 7.468.588 2.266 1.222 3.994 2.399 4.946.77.373 4.487 1.65 7.889 2.571.801.04 2.586 1.98 5.363 1.811 1.233-.074 2.004-2.027 2.105-3.422.06-5.177-1.35-15.111-2.358-18.94-.888-2.821-2.481-6.063-5.351-5.857m4.555 6.041c.99 4.296 2.32 12.735 2.358 18.86-.258 1.408-1.19 2.612-1.683 2.642-1.77.105-3.736-1.582-4.493-1.761-1.549-.254-6.91-2.138-7.983-2.622-.722-.998-1.59-2.394-1.964-4.523-.132-.849.966-4.002 1.825-6.791S3.748 7.98 4.284 6.48C4.866 4.724 8.73 1.257 10.262.83c1.77-.038 3.874 2.633 4.408 5.291M6.569 20.097c.566.965 2.651 1.481 3.936 2.06.662-.534-.723-3.033-.08-3.407.415-.24 2.854 3.935 3.829 3.706.293-.268.36-.868.33-1.644-.075-1.952-1.266-10.263-1.596-10.426-.812.525 1.108 10.01.653 10.368-1.08-.515-2.8-3.912-3.859-3.718-.517.377.088 2.49.024 2.849-.032.177.405.82-.288.864-1.466-.328-2.261-.895-2.95-.652"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={0.012}
      d="M10.37.826c-.827.17-1.5.747-2.173 1.223a19 19 0 0 0-3.232 3.214c-.91 1.168-1.096 2.688-1.518 4.065-.73 2.88-1.814 5.662-2.487 8.558-.225.922.165 1.843.446 2.707.366.91.79 1.829 1.433 2.575.637.439 1.425.592 2.131.89a78 78 0 0 0 5.981 1.882c1.296.527 2.436 1.532 3.874 1.656.382.039.827.057 1.073-.3.626-.675 1.057-1.568 1.13-2.49-.068-4.593-.636-9.165-1.377-13.694-.361-1.99-.676-3.998-1.232-5.945-.553-1.611-1.553-3.157-3.039-4.046-.31-.168-.653-.3-1.01-.295Zm2.673 9.726c.44 1.569.62 3.197.88 4.803.25 1.97.61 3.937.62 5.926-.043.384.02.924-.348 1.173-.65-.15-1.063-.768-1.506-1.221-.725-.791-1.292-1.73-2.085-2.454-.285-.166-.394.271-.35.476.037.923.53 1.817.354 2.744-.13.243-.442-.058-.626-.073-1.135-.49-2.446-.777-3.314-1.72-.17-.201.249-.104.366-.123.91.092 1.736.616 2.653.641.356-.082.183-.552.162-.8-.1-.88-.338-1.778-.199-2.661.069-.334.477-.187.657-.052.923.693 1.53 1.71 2.293 2.563.324.325.581.79 1.022.963.198-.107.071-.43.097-.613-.21-2.748-.746-5.466-.89-8.217.016-.497-.08-1.033.155-1.493z"
    />
    
    
  
  </svg>
);
export default Diente13;