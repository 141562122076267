import React, { useReducer } from "react";
import axios from 'axios';
import axios2 from 'services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NuDropdown } from "../../components/nubox/NuDropdown";

import ImageUpload from "components/nubox/ImageUpload";
import confirmService from 'components/confirmService';

import { withRouterJim } from "router/withRouterJim";

import user from 'assets/img/user.jpg';
import user1 from 'assets/img2/user1.png';

//--- CONTEXT API -----------
import { useStore, useDispatch, StoreContext } from "store/StoreProvider";
import {types, storeReducer} from "store/StoreReducer"

import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  ToastHeader,
} from "reactstrap";



const cbo=[
  {  value:'1', label: 'label1'},
  {  value:'2', label: 'Clss'},
  {  value:'3', label: 'Nod'},
  {  value:'4', label: 'Lod'},
  {  value:'5', label: 'Elggg'},
]

const form_reset={
  idd:0,
  ope:'n',  
  nombre: "",
  sexo: "M",
  fecha_nac: "",
  id_historia: 1,
  id_paciente: 6,
  fecha_reg: "",
  motivo_consulta: "",
  enfermedad: "",
  medicamento: "",
  enfer_anterior_sn: 0,
  enfer_anterior: "",
  tomando_medic_sn: 0,
  tomando_medic: "",
  hospitalizado_sn: 0,
  hospitalizado: "",
  fuma_sn: 0,
  bebe_sn: 0,
  alergias_sn: 0,
  hizoc_anestesia_sn: 0,
  embarazo_sn: 0,
  embarazo: "",
  obs: '',
}


//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
class Historia extends React.Component {
  //---- context API
  static contextType  = StoreContext;

  state = {
    modalImagen: false,
    id_aux:0,
    
    form: {
      idd:0,
      ope:'n',  
      nombre: "CHRISTIAM ALEXIS DIAZ SAONA",
      sexo: "M",
      fecha_nac: "1987-09-17",
      id_historia: 1,
      id_paciente: 6,
      fecha_reg: "2023-02-15 05:03:23",
      motivo_consulta: "dfsdf",
      enfermedad: "sdfsdf",
      medicamento: "sdf",
      enfer_anterior_sn: 0,
      enfer_anterior: "sdf",
      tomando_medic_sn: 0,
      tomando_medic: "sdf",
      hospitalizado_sn: 0,
      hospitalizado: "",
      fuma_sn: 0,
      bebe_sn: 0,
      alergias_sn: 0,
      hizoc_anestesia_sn: 0,
      embarazo_sn: 0,
      embarazo: "",
      obs: null
      
    },
    
    
  };
  

  

  //------------------------------------------------------
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    //this.setState({fechai: defaultValue}); 
    //this.setState({ form:{...this.state.form, fecha: defaultValue,}}); 
    let id_ = this.props.store.paciente.id_paciente;
    // Get the userId param from the URL.
    let idd = this.props.params.idd;
    let ope = this.props.params.ope;  
    
    if(ope==='e' || ope==='x'){
      this.cargar_historia(idd, ope);
    }

    if(ope==='n'){
      this.limpiar();
    }

    this.setState({ form:{
      ...this.state.form, 
      idd: idd, 
      ope: ope,
      id_paciente:id_,
    }}); 
    
    //this.cargar_citas();
    //this.fetchData(this.state.fechai);
  }

  //------------ 
  limpiar(){
    this.setState({form: form_reset});
  }
  //----------------especial, actualiza valores del form -------------------------------
  handleChangeForm = (e) => {
    //requerimos la prop name="?" en cada control
    
    //--- esto lo hacemos porque en vez de utilizar valores true, false
    //--- tenemos que utilizar 1, 0
    if(e.target.type==="checkbox"){
      //console.log("cheched:"+ e.target.checked);
      let res = e.target.checked? 1:0;
      //console.log("value:"+ res);
      
      this.setState({
        form:{
          ...this.state.form,
          [e.target.name]: res,
        }
      })
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  
  isEmptyObject= (obj) => {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                return false;
            }
        }
     
        return true;
  }

  // Toggle for Modal
  
  //====================================================== 
  //----cargamos los datos desde la base de datos
  /*{
    "id_paciente": 1,
    "nombre": "ALEX LIZARRAGA",
    "nro_doc": "00000023",
    "fecha_nac": "2022-01-13",
    "foto_path1": "1665435807.jpg",
    "dir": "Cerro Bola #319",
    "cel": "0123456789"
  },*/
  cargar_historia = (idd,ope) => {
    
    //context API
    console.log("context API");
    //const miContext = this.context;
    //console.log(miContext);
    const [ store, dispatch ] = this.context;
    const {user, paciente} = store;

    let param = { 
      id_historia: idd
    }
    console.log(param);
    axios2.post(process.env.REACT_APP_API_URL  + "historiacrud_buscar", param)
    .then(res => {
      
        if(! this.isEmptyObject(res.data)){ 
            
            this.setState({
              form: {...res.data, idd:idd, ope:ope}});
            //añadimos el id y ope
            /*this.setState({
              form: {  ...this.state.form, 
                idd: idd,  
                ope: ope,
              },
            });*/
            
            //---------------
        }else{
          toast.error("Registro no encontrado", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

    }).catch(error => {
        alert("---- Existio un error, ---");
        console.log(error);
        
    });
  };
      
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //----------------
  
  /* === This is where the magic happens ==== */
  handleSubmit = event => {
    event.preventDefault();
    this.cargar_pacientes();
  }

  //-----------------------------------------------
  aceptar_click = async () => {
    console.log("aceptar");
    
    confirmService.show({
      message: 'Esta seguro de guardar?',
      title: 'Confirmation',
      icon: 'fa fa-question-circle',
      classHeader: 'bg-default1 ',
      classButton: 'btn btn-danger',
      accept: () => {
          
          this.save();
      },
      reject: () => {
          console.log('cancel');
      },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar'
    })
 

    
    //save();
}
save =() =>{
  const [ store, dispatch ] = this.context;
  const {user, paciente} = store;

  let param =  this.state.form;
  console.log(param);
  axios2.post("historiacrud_store", param)
  .then(res => {
      console.log(res.data);
      if(res.data.exito === 1){ 
          toast.success(res.data.mensaje);
          //--- tenemos que redireccionar
          let id_paciente = paciente.id_paciente;
          this.props.navigate('/historialist/'+ id_paciente);
          
      }else{
          toast.error(res.data.mensaje);
      }

  }).catch(error => {
      alert("---- Existio un error ---");
      console.log(error);
      
  });
  
}
  //-------------------------------------
  render() {
    //------------ context API --------------
    const [ store, dispatch ] = this.context;
    const {user, paciente} = store;

    console.log( process.env.REACT_APP_AFILES + this.state.form.foto_path1 );
    const {form} = this.state;

    return (
      <div>
        <ToastContainer />
        
        {/* JSON.stringify(form) */}

        <div className="content">

          {/* ---   --- */}
          {paciente.nombre}
          { JSON.stringify( this.state.form) }
          {/*JSON.stringify(this.props.store.paciente)*/}


          <section className="content-header">
            <h3>
              Historia
            </h3>
          </section>


          <section className="content">
            <form onSubmit = { this.handleSubmit }>
              
              <div className="row">
                

                {/*<!-- /.col -->*/}
                <div className="col-md-12">

                  <div className="box box-warning collapsed-box1">
                    <div className="box-header with-border">

                

                     
                        
                    </div>
                  
                    {/* ---  -----------------------------------------------  --- */}
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">

                            <label className="col-form-label">Cod historia</label>
                            <input
                              type="text"
                              className="form-control" readOnly="readonly"
                              name="id_historia"
                              value={ form.id_historia }
                              onChange={this.handleChangeForm}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="col-form-label">Sexo</label>
                            <br/>
                            <label>{form.sexo}</label>
                          </div>
                          
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="col-form-label">Edad</label>
                            <br/>
                            <label>{form.fecha_nac}</label>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="col-form-label">Fecha registro</label>
                            <br/>
                            <label>{form.fecha_reg}</label>
                          </div>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">

                            <label className="col-form-label">Motivo *</label>
                            <input
                              type="text"
                              className="form-control" 
                              name="motivo_consulta"
                              value={ form.motivo_consulta }
                              onChange={this.handleChangeForm}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Enfermedad</label>
                            <input
                              type="text"
                              className="form-control" 
                              name="enfermedad"
                              value={ form.enfermedad }
                              onChange={this.handleChangeForm}
                            />
                          </div>
                          
                        </div>
                      </div> 

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">

                            <label className="col-form-label">Medicamento</label>
                            <input
                              type="text"
                              className="form-control" 
                              name="medicamento"
                              value={ form.medicamento }
                              onChange={this.handleChangeForm}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            
                          </div>
                          
                        </div>
                      </div> 

                      <div className="block0"  >
                        <span ><b>Consulta de salud</b></span>
                      </div>

                      <table className="tabla_basic"> 
                        <tbody>
                          <tr>
                            <td style={{ width: "200px" }}>
                              <label className="col-form-label">Enfermedad anterior </label>
                            </td>
                            <td >
                              <input 
                                  name="enfer_anterior_sn" 
                                  type="checkbox" 
                                  value = {this.state.form.enfer_anterior_sn }
                                  checked={this.state.form.enfer_anterior_sn===1 }
                                  onChange={this.handleChangeForm}  />
                            </td>
                            <td>
                              <span >
                                {  
                                  <input  readOnly={ this.state.form.enfer_anterior_sn===0 ? 'readonly' : ''}
                                    type="text" 
                                    name="enfer_anterior" 
                                    value={this.state.form.enfer_anterior} 
                                    onChange={this.handleChangeForm} 
                                    className="form-control" 
                                    placeholder=""/>
                                }
                              </span>
                            </td>

                          </tr>
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Esta en tratamiento médico</label>
                            </td>
                            <td>
                              <input 
                                  name="tratamiento_sn" 
                                  type="checkbox"
                                  value = {this.state.form.tratamiento_sn }
                                  checked={this.state.form.tratamiento_sn===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              <span >
                                { 
                                  <input readOnly={ this.state.form.tratamiento_sn===0 ? 'readonly' : ''}
                                    name="tratamiento" 
                                    type="text"
                                    value={this.state.form.tratamiento} 
                                    onChange={this.handleChangeForm} 
                                    className="form-control" 
                                    placeholder=" " />
                                }
                              </span>
                            </td>
                          </tr>
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Fué hospitalizado:</label>
                            </td>
                            <td>
                              <input 
                                  name="hospitalizado_sn" 
                                  type="checkbox"
                                  value = {this.state.form.hospitalizado_sn }
                                  checked={this.state.form.hospitalizado_sn===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              <span >
                                { 
                                  <input readOnly={ this.state.form.hospitalizado_sn===0 ? 'readonly' : ''}
                                    name="hospitalizado" 
                                    type="text"
                                    value={this.state.form.hospitalizado} 
                                    onChange={this.handleChangeForm} 
                                    className="form-control" 
                                    placeholder=" " />
                                }
                              </span>
                            </td>
                          </tr>
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Fuma:</label>
                            </td>
                            <td>
                              <input 
                                  name="fuma_sn" 
                                  type="checkbox"
                                  value = {this.state.form.fuma_sn }
                                  checked={this.state.form.fuma_sn===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              
                            </td>
                          </tr>
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Bebe:</label>
                            </td>
                            <td>
                              <input 
                                  name="bebe_sn" 
                                  type="checkbox"
                                  value = {this.state.form.bebe_sn }
                                  checked={this.state.form.bebe_sn===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              
                            </td>
                          </tr>
                          
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Alergias:</label>
                            </td>
                            <td>
                              <input 
                                  name="alergias_sn" 
                                  type="checkbox"
                                  value = {this.state.form.alergias_sn }
                                  checked={this.state.form.alergias_sn===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              <span >
                                { 
                                  <input readOnly={ this.state.form.alergias_sn===0 ? 'readonly' : ''}
                                    name="alergias" 
                                    type="text"
                                    value={this.state.form.alergias} 
                                    onChange={this.handleChangeForm} 
                                    className="form-control" 
                                    placeholder=" " />
                                }
                              </span>
                            </td>
                          </tr>
                          
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Se hizo colocar anestesia:</label>
                            </td>
                            <td>
                              <input 
                                  name="hizoc_anestesia" 
                                  type="checkbox"
                                  value = {this.state.form.hizoc_anestesia }
                                  checked={this.state.form.hizoc_anestesia===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              
                            </td>
                          </tr>
                          {/* ---   --- */}
                          <tr>
                            <td >
                              <label className="col-form-label">Embarazo:</label>
                            </td>
                            <td>
                              <input 
                                  name="embarazo_sn" 
                                  type="checkbox"
                                  value = {this.state.form.embarazo_sn }
                                  checked={this.state.form.embarazo_sn===1 }
                                  onChange={this.handleChangeForm} 
                                  />
                            </td>
                            <td>
                              <span >
                                { 
                                  <input readOnly={ this.state.form.embarazo_sn===0 ? 'readonly' : ''}
                                    name="embarazo" 
                                    type="text"
                                    value={this.state.form.embarazo} 
                                    onChange={this.handleChangeForm} 
                                    className="form-control" 
                                    placeholder="Tiempo" />
                                }
                              </span>
                            </td>
                          </tr>
                          {/* ---   --- */}
                        </tbody>
                      </table>


                      <div className="block0" >
                        <span ><b>Enfermedad o problema actual</b></span>
                      </div>

                      <div className="row">
                        <div className="col-md-12 p-3">
                          <div className="form-group">
                            <label className="control-label">Observaciones</label>
                            <textarea 
                              name="obs" 
                              className="form-control" 
                              value={this.state.form.obs} 
                              onChange={this.handleChangeForm} 
                              rows="3"></textarea>
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* ---  -----------------------------------------------  --- */}
                    <div className="box-footer">
                      <div className="form-group">
                        <div className="text-center">
                          <Button onClick={ this.aceptar_click } className="btn btn-success">
                            <i className="fa fa-floppy-o"></i>&nbsp;
                            Guardar Datos
                          </Button>
                          &nbsp;
                          <button className="btn btn-default">Cancelar</button>
                        </div>
                      </div>
                    </div>
                    {/* ---   --- */}
                    <div id="divres"></div>
                    {/* ---   --- */}
                  </div>
                </div>
              </div>
              
            </form>
          </section>
              
        </div>



        <Modal isOpen={this.state.modalImagen}
          toggle={ this.toggle }
          >
          <ModalHeader style={{display: 'block'}}>
            Subir archivo png/jpg
            <span style={{float: 'right'}} onClick={()=>this.toogle()}>x</span>
          </ModalHeader>
          <ModalBody>
              <ImageUpload 
                okModal={ this.okUploadedModal}
                closeModal={ this.closeUploadedModal}
                fileName="fileName"
              />
          </ModalBody>
        </Modal>

      </div>
    
    );
  }
}
  
//------------para que soporte la funcionalidad de parametros
export default withRouterJim(Historia);