import { faRegistered } from '@fortawesome/free-solid-svg-icons'

import React, {useEffect, useState} from 'react'
import './style.css'

import FondoSvg from "./FondoSvg"
import CirculoCaries from "./CirculoCaries"
import Diente18 from "./Diente18"
import Diente15 from "./Diente15"
import Diente13 from "./Diente13"
import Diente12 from "./Diente12"
import Diente11 from "./Diente11"

import MySvg from "./MySvg"

//import completo2 from 'assets/completo2.svg'

const data_init =[{ "pieza":"11,12", id_prod :2, partes:  "10000", color:"#efefef"}]

class Odontograma extends  React.Component {
  
  constructor(props){
    super(props);
    //--aqui declaramos el estado
    this.state ={
      isActive: false,
      data: data_init,
      date: new Date(), /* manejo time*/
    }

    
    /*setInterval(
      ()=> this.setState({
        date: new Date(),
        isActive: this.verifica_si_svg_cargado(),
      }),
      1000
    );*/
    
  }

  componentDidMount(){ 
    //-------------
    /*setTimeout(() => {
        
      if(document.querySelector(".svgClass").getSVGDocument()!==null){
        
        console.log("cargado correcto");
        //setIsActive(true);
        this.svg_cargado();
        this.setState({ isActive: true })
  
      }
    }, 1000);
    */
    //console.log(document.querySelector(".svgClass").getSVGDocument());
  }
  //------------------------------------
  /*verifica_si_svg_cargado=()=>{
    let sol=false;
    if(this.state.isActive!==false){ return;}
    if(document.querySelector(".svgClass").getSVGDocument()!==null ){
      sol=true;
    }
    //---solo lo cargamos una vez, la primera vez
    if(sol==true && this.state.isActive===false){
      this.svg_cargado();
    }
    return sol;
  }*/
  //-------------------------------------
  svg_cargado=()=>{
    this.clear_odontograma();
    this.inyeccode(); 
    this.inyecStyle_hover();
    //this.cambiar_color_partes("0",this.props.value,"#f20");
    
  }
  //-------------------------------------
  test1=()=>{
    console.log("test1");
    this.cambiar_color();
  }
  test2=()=>{
    console.log("test2");
    this.inyeccode();
  }
  cambiar_color=()=>{
    // change to red
    //elemSvg.getElementById("svgInternalID").addEventListener("fill", "red")
    let _currentFill = "#f00"; // red
    /*$svg = $("#octocat");
    $("#face", $svg).attr('style', "fill:"+_currentFill);*/

    let elemSvg = document.querySelector(".svgClass").getSVGDocument();
    
    elemSvg.getElementById("layer2").addEventListener("style", "fill:orange");
    elemSvg.getElementById("layer3").addEventListener("style", "fill:green");
    elemSvg.getElementById("layer20").addEventListener("style", "fill:orange");
    elemSvg.getElementById("layer22").addEventListener("style", "fill:orange");

    
}
  //----------------------------------
  inyeccode=()=>{
    //--- inyeccion de codigo onclick
    //elemSvg.getElementById("layer2").addEventListener("onclick", `alert('You have clicked the circle.');`);
    //--- inyeccion de codigo onclick
    let elemSvg = document.querySelector(".svgClass").getSVGDocument();

    elemSvg.getElementById("d_18").addEventListener("click", () => this.mostrar_opt(18));
    elemSvg.getElementById("layer22").addEventListener("click", () => this.mostrar_opt(17));
    elemSvg.getElementById("layer24").addEventListener("click", () => this.mostrar_opt(16));
    elemSvg.getElementById("layer26").addEventListener("click", () => this.mostrar_opt(15));
    elemSvg.getElementById("layer28").addEventListener("click", () => this.mostrar_opt(14));
    elemSvg.getElementById("layer31").addEventListener("click", () => this.mostrar_opt(13));
    elemSvg.getElementById("layer30").addEventListener("click", () => this.mostrar_opt(12));
    elemSvg.getElementById("g4900").addEventListener("click", () => this.mostrar_opt(11));
    elemSvg.getElementById("layer34").addEventListener("click", () => this.mostrar_opt(21));
    elemSvg.getElementById("layer33").addEventListener("click", () => this.mostrar_opt(22));
    elemSvg.getElementById("layer32").addEventListener("click", () => this.mostrar_opt(23));
    elemSvg.getElementById("layer29").addEventListener("click", () => this.mostrar_opt(24));
    elemSvg.getElementById("layer27").addEventListener("click", () => this.mostrar_opt(25));
    elemSvg.getElementById("layer25").addEventListener("click", () => this.mostrar_opt(26));
    elemSvg.getElementById("layer23").addEventListener("click", () => this.mostrar_opt(27));
    elemSvg.getElementById("layer21").addEventListener("click", () => this.mostrar_opt(28));
    
    elemSvg.getElementById("layer2").addEventListener("click", () => this.mostrar_opt(48));
    elemSvg.getElementById("layer3").addEventListener("click", () => this.mostrar_opt(47));
    elemSvg.getElementById("layer4").addEventListener("click", () => this.mostrar_opt(46));
    elemSvg.getElementById("layer9").addEventListener("click", () => this.mostrar_opt(45));
    elemSvg.getElementById("layer11").addEventListener("click", () => this.mostrar_opt(44));
    elemSvg.getElementById("layer14").addEventListener("click", () => this.mostrar_opt(43));
    elemSvg.getElementById("layer16").addEventListener("click", () => this.mostrar_opt(42));
    elemSvg.getElementById("layer18").addEventListener("click", () => this.mostrar_opt(41));
    elemSvg.getElementById("layer19").addEventListener("click", () => this.mostrar_opt(31));
    elemSvg.getElementById("layer17").addEventListener("click", () => this.mostrar_opt(32));
    elemSvg.getElementById("layer15").addEventListener("click", () => this.mostrar_opt(33));
    elemSvg.getElementById("layer13").addEventListener("click", () => this.mostrar_opt(34));
    elemSvg.getElementById("layer8").addEventListener("click", () => this.mostrar_opt(35));
    elemSvg.getElementById("layer7").addEventListener("click", () => this.mostrar_opt(36));
    elemSvg.getElementById("layer6").addEventListener("click", () => this.mostrar_opt(37));
    elemSvg.getElementById("layer5").addEventListener("click", () => this.mostrar_opt(38));
  }
  //-----------------------
  mostrar_opt=(pieza)=>{
    this.props.onClickPieza(pieza);
  }
  //-----------------------
  inyecStyle_hover=()=>{
    //---
    let estilo= `<style type="text/css"><![CDATA[
      #layer20:hover{ fill: gold; }
      #layer22:hover{ fill: gold; }
      #layer24:hover{ fill: gold; }
      #layer26:hover{ fill: gold; }
      #layer28:hover{ fill: gold; }
      #layer31:hover{ fill: gold; }
      #layer30:hover{ fill: gold; }
      #g4900:hover{ fill: gold; }
      #layer34:hover{ fill: gold; }
      #layer33:hover{ fill: gold; }
      #layer32:hover{ fill: gold; }
      #layer29:hover{ fill: gold; }
      #layer27:hover{ fill: gold; }
      #layer25:hover{ fill: gold; }
      #layer23:hover{ fill: gold; }
      #layer21:hover{ fill: gold; }

      #layer3:hover{ fill: gold; }
      #layer2:hover{ fill: gold; }
      #layer4:hover{ fill: gold; }
      #layer9:hover{ fill: gold; }
      #layer11:hover{ fill: gold; }
      #layer14:hover{ fill: gold; }
      #layer16:hover{ fill: gold; }
      #layer18:hover{ fill: gold; }
      #layer19:hover{ fill: gold; }
      #layer17:hover{ fill: gold; }
      #layer15:hover{ fill: gold; }
      #layer13:hover{ fill: gold; }
      #layer8:hover{ fill: gold; }
      #layer7:hover{ fill: gold; }
      #layer6:hover{ fill: gold; }
      #layer5:hover{ fill: gold; }
      
    ]]></style>`;

    let elemSvg = document.querySelector(".svgClass").getSVGDocument();
    elemSvg.getElementsByTagName('defs')[0].innerHTML = estilo; 
  };
  //=================================================================
  render_diente=(pieza, id_prod, color, partes,interno_sn)=>{
  
    switch(id_prod){
        case 1: break
        case 2: // caries
                this.cambiar_color_partes(pieza, partes, color); break;
        case 6:
                this.cambiar_a_periodoncia(pieza, color,interno_sn); break;
        case 7:
                this.cambiar_ortodoncia(pieza, color); break;
        case 9: //acrilico
        case 10:  //cromo
                this.cambiar_a_protremov(pieza, color); break;
  
        default:// todos los que pinta el diente completo
                this.cambiar_color_diente(pieza,color); break;
    }
  }
  //-----------------------------------------
  //---colocamos a estado 0 todo el odontograma
  clear_odontograma=()=>{
  
    let dato = [18,17,16,15,14,13,12,11,21,22,23,24,25,26,27,28,48,47,46,45,44,43,42,41,31,32,33,34,35,36,37,38];
    for (var i=0; i<dato.length; i++) 
    {
      let pieza = dato[i] +"";  //lo pasamos a string
      this.cambiar_a_reset(pieza);
    }
  }
  //---pintamos color blanco la pieza
  cambiar_a_reset=(pieza)=>{
    let elem = document.querySelector(".svgClass").getSVGDocument();

    let color = "#fff"; // blanco
    let col_rosado= "#ffaaaa";
    let idx='';
    let idi='';
    //---para el diente
    this.cambiar_color_diente(pieza,color)
    //--- colocamos el circulo color blanco
    idx= "c_" + pieza;
    elem.getElementById(idx).setAttribute("style", "fill:"+ color);
    //--- los palitos blancos
    idx= "be_" + pieza;
    idi= "bi_" + pieza;
    elem.getElementById(idx).setAttribute("style", "fill:"+ color);
    //---los internos tienen que ser color rosado
    let id_pieza = parseInt(pieza);
    
    if( (id_pieza>=11 && id_pieza<=18) || (id_pieza>=21 && id_pieza<=28)){
      
      //---color rosado
      elem.getElementById(idi).setAttribute("style", "fill:"+ col_rosado);
    }else{
      //--color blanco normal
      elem.getElementById(idi).setAttribute("style", "fill:"+ color);
    }
    
    //-- las partes
    let piezax = "m" +  pieza;
    elem.getElementById( piezax +"_0").setAttribute("style", "fill:white");
    elem.getElementById( piezax +"_1").setAttribute("style", "fill:white");
    elem.getElementById( piezax +"_2").setAttribute("style", "fill:white");
    elem.getElementById( piezax +"_3").setAttribute("style", "fill:white");
    elem.getElementById( piezax +"_4").setAttribute("style", "fill:white");  
  }
  //--------------------------------------------------
  cambiar_color_diente=(pieza,color)=>{
    //console.log("pieza:"+ pieza); //pieza:23,25,1
    let elem = document.querySelector(".svgClass").getSVGDocument();
      
    const myArray = pieza.split(",");
    //--ahora cada elemento, lo recorremos 
    for (let i = 0; i < myArray.length ; i++) {
      // d_18, d_17, ......
      let idx= "d_" + myArray[i];
      //console.log("idx:"+idx);

      if(elem.getElementById(idx)!=null){
        //ahora si lo cambiamos de color
        elem.getElementById(idx).setAttribute("style", "fill:"+ color);
      }
      
    }
      
  } 
  //----------------------------
  cambiar_ortodoncia(pieza, color){
    let elem = document.querySelector(".svgClass").getSVGDocument();

    const myArray = pieza.split(",");
    //--ahora cada elemento, lo recorremos 
    for (let i = 0; i < myArray.length ; i++) {
      //c_18, c_17, ......
      let idx= "c_" + myArray[i];
      //ahora si lo cambiamos de color
      if(elem.getElementById(idx)!=null){
        elem.getElementById(idx).setAttribute("style", "fill:"+ color);
      }
    }
  }
  //--ingresa una cadena y con ello lo pintamos
  //---pueden venir multiples piezas, partes---------------------------------------------------
  //cad 01101
  cambiar_color_partes=(pieza, cade, color)=>{
    
    //let _currentFill = "#f00"; // red
    let _currentFill = color;

    const myArray = pieza.split(",");
    if(myArray.length<0){
      return;
    }
    //-----------------------------
    this.cambiar_color_diente(pieza, color);
    //--obtenemos la pieza
    let piezax = "m" + myArray[0];
    
    //----- la cadena desde afuera 01011, de pintado
    let arr = cade.split('');
    if(arr.length!=5){
        alert("Error 34");
        return;
    }

    let elemSvg = document.querySelector(".svgClass").getSVGDocument();

    //---si es '0', ocultamos la muestra 
    if(cade=='00000' || cade==''){
      elemSvg.getElementById( piezax +"_0").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_1").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_2").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_3").setAttribute("style", "fill:silver");
      elemSvg.getElementById( piezax +"_4").setAttribute("style", "fill:silver");  
        return;
    }
    //-----------pintamos los dientes
    for (let i = 0; i < arr.length; i+=1) { 
      //console.log("En el índice '" + i + "' hay este valor: " + miArray[i]);
      //console.log(piezax +"_"+ i);
      if(arr[i]==='1'){
        //elemSvg.getElementById( piezax +"_"+ i).setAttribute("style", "fill:"+_currentFill);
        elemSvg.getElementById( piezax +"_"+ i).setAttribute("style", "fill:maroon");
      }else{
        elemSvg.getElementById( piezax +"_"+ i).setAttribute("style", "fill:silver");
      }
    } 

  }
  //-------------------------------
  cambiar_a_periodoncia=(pieza, color, interno_sn)=>{
    let elem = document.querySelector(".svgClass").getSVGDocument();
  
    const myArray = pieza.split(",");
    //--ahora cada elemento, lo recorremos 
    for (let i = 0; i < myArray.length ; i++) {
      //be_18, be_17, ......
      let idx= "be_" + myArray[i];
      let idi= "bi_" + myArray[i];
  
      if(interno_sn==0){  //es no 
        //ahora si lo cambiamos de color
        elem.getElementById(idx).setAttribute("style", "fill:"+ color);
      }else{
        //ahora si lo cambiamos de color
        elem.getElementById(idi).setAttribute("style", "fill:"+ color);
      }
      
      //case 18: elem.getElementById("be_18").setAttribute("style", "fill:"+ color); break;
    }
  
  }
  //--------------------------------
  cambiar_a_protremov=(pieza, color)=>{
    let elem = document.querySelector(".svgClass").getSVGDocument();
    
    const myArray = pieza.split(",");
    //--ahora cada elemento, lo recorremos 
    for (let i = 0; i < myArray.length ; i++) {
      //be_18, be_17, ......
      let idx= "be_" + myArray[i];
      let idi= "bi_" + myArray[i];
  
      //ahora si lo cambiamos de color
      elem.getElementById(idx).setAttribute("style", "fill:"+ color);
      elem.getElementById(idi).setAttribute("style", "fill:"+ color);   
      
    }
  
  }
  //------- metodo publico , desde afuera para render manual---------------------
  render_odontograma=()=>{
    this.clear_odontograma();
    
    for (let item of this.props.data) {
      //var value = this.data[property];

      let pieza = item.pieza;
      let id_prod  = item.id_prod;
      let partes  = item.partes;
      let color  = item.color;
      let interno_sn= item.interno_sn;

      //----cambiamos el color al diente
      this.render_diente(pieza, id_prod, color, partes, interno_sn);
      //cambiar_color_diente(id_pieza,color);
    }
  }
 //==================================================
 render(){
    const { isActive } = this.state
    
    //---solo si ya esta cargado el SVG
    if(isActive==true){
      
      //this.render_odontograma(); 
    }
  return (
    <>
      {/*<h2>Mi datatable en react</h2>*/}
      {/* this.props.dataSource.length */}
      
      { 
        //JSON.stringify(this.state.isActive ) 
      }
      {/*<h2>Hora: {this.state.date.toLocaleTimeString()}.</h2>
      */}
      <div style={{ position: 'relative', width:'600', height:'400'}}>
        <MySvg width={50} height={50} style={{position:'absolute', top:80}}/>
        <FondoSvg  style={{ position: 'absolute',zIndex:0,top:0, left:0, width:'660px',height:'390px',
          border: '1px solid #ccc'
         }}/>
        <CirculoCaries cade='11110' width={75} height={75} style={{ position: 'absolute',top: '80', left: '70' }}/>
        <Diente18 width={50} height={50} style={{position:'absolute', top:'260', left:'70'}}/>
        <Diente18 width={50} height={50} style={{position:'absolute', top:'215', left:'70'}}/>
        <Diente18 width={50} height={50} style={{position:'absolute', top:'173', left:'77'}}/>
        <Diente15 pza={15} width={34} height={46} style={{position:'absolute', top:'137', left:'86'}}
                  className="di resalte1"/>
        <Diente15 pza={14} width={34} height={46} style={{ top:'107', left:'97'}}
                  className="di resalte1"/>

        <Diente13 pza={13}  width={36} height={44} style={{ top:'79', left:'106'}}
                  className="di resalte1"/>
        <Diente12 pza={12} width={44} height={44} style={{ top:'64', left:'119'}}
                  className="di resalte1"/>
        <Diente11 pza={11} width={44} height={44} color='white' style={{ top:'58', left:'144'}}
                  className="di resalte1"/>

        {/*-- espejo ---*/}
        <Diente11 pza={21} width={44} height={44}  color='red' style={{ top:'58', left:'171',transform: 'rotateY(180deg)'}} 
                  className="di resalte1" />
        <Diente12 pza={22} width={34} height={44} style={{ top:'64', left:'200',transform: 'rotateY(180deg)'}}
                  className="di resalte1"/>

      </div>
      {/*<object className="svgClass" 
              width="100%" 
              height="auto" 
              type="image/svg+xml" 
              data={completo2}
              style={{ fill: "red", stroke: "blue", strokeWidth: "2" }}>
      </object>*/}
      
      
      
        
      {/*loader*/}

      {/*<button className="btn btn-success" onClick={ this.test1 } >Test1</button>
      <button className="btn btn-primary" onClick={ this.test2 } >Test2</button>*/}
    </>
    );
  }
}

export default Odontograma;