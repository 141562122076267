import * as React from "react";

const Diente15 = (props) => (
  <svg style={{ width: "inherit", height: "inherit" }}
          version="1.1"
          viewBox="0 0 18.95042 27.892736"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          {...props}
      >
   
   
   <path
      fill="#000"
      fillRule="evenodd"
      d="M5.725.137c3.563.286 9.089 6.635 11.21 10.297 2.319 5.003 1.513 8.831.333 11.812C15.9 25.062 8.63 27.994 7.258 28c-3.225-.39-4.012.298-6.247-2.37C.327 24.056-.501 10.07.72 7.807 1.251 6.544 3.66.762 5.725.137m.218 1.229C4.578 1.47 1.85 7.789 1.719 8.508c-1.009 3.104-.373 14.506.058 16.08.851 2.074 3.593 2.457 5.315 2.52 1.69-.249 8.155-3.072 9.406-5.864 1.294-3.297 1.07-7.208-.501-10.502C13.61 7.67 9.144 1.12 5.943 1.366m2.08 5.96c.617-.037 1.855.946 2.31 2.563.777.796 1.384 1.002 1.353 1.505-.75.35-.961-1.235-2.09.153-.77 1.327-.809 1.566-1.403 3.368-.242 1.433-.005 4.29.369 5.492.133.429 1.39.458 1.33.81-.354.204-.85.146-1.464.047-.49-.098-.893.103-1.361.292-.749-.032-1.302-.373-1.33-.614.113-.51 1.327.4 1.528-.471.378-1.404-.136-4.453.246-5.767.425-1.973 1.61-3.667 1.81-4.613.294-1.385-1.226-1.705-1.299-2.765"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={0.012}
      d="M5.929 1.365c-.58.098-.906.684-1.249 1.104-1.184 1.736-2.086 3.654-2.832 5.614-.659 1.947-.6 4.038-.666 6.067-.01 3.343.058 6.7.504 10.017.143 1.015.986 1.804 1.882 2.215 1.148.49 2.414.767 3.665.711 2.084-.527 4.028-1.515 5.863-2.62 1.24-.803 2.537-1.683 3.281-2.999.617-1.277.874-2.71.941-4.12.106-2.233-.29-4.51-1.289-6.518-1.004-1.489-2.146-2.883-3.259-4.292-1.495-1.796-3.035-3.677-5.132-4.792a3.57 3.57 0 0 0-1.71-.387Zm2.218 5.981c.927.268 1.566 1.132 1.961 1.975.2.784.962 1.177 1.457 1.749.097.108.145.36-.072.325-.477.023-.816-.635-1.311-.376-.72.453-1.021 1.32-1.363 2.056-.242.65-.529 1.304-.673 1.979-.04 1.789-.106 3.63.42 5.36.273.44.93.37 1.265.732-.096.235-.457.16-.67.178-.55-.027-1.12-.21-1.649.047-.555.23-1.271.14-1.682-.321-.125-.159.061-.27.219-.213.36-.05.846.166 1.125-.145.28-.599.166-1.298.215-1.94.055-1.41-.11-2.847.18-4.233.363-1.512 1.215-2.84 1.722-4.3.115-.518.063-1.116-.352-1.496-.32-.413-.795-.795-.885-1.333.007-.043.057-.049.093-.044Z"
    />
    
    
  
  </svg>
);
export default Diente15;