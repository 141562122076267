import {NavLink}  from 'react-router-dom'

const Sidebar =()=>{
  return(
    <div className="sidebar bg-light">
      <ul>
        <li>
          <NavLink to="/#" exact className="text-dark rounded  py-2 w-100 d-inline-block px-3" active ClassName="active">
            <i className="fa fa-home me-2"/>
            Inicio
            </NavLink>
        </li>
        <li>
          <NavLink to="/ticket" exact className="text-dark rounded py-2 w-100 d-inline-block px-3" active ClassName="active">
          <i className="fa fa-edit me-2"></i>Ticket</NavLink>
        </li>
        <li>
          <NavLink to="/certificado" exact className="text-dark rounded py-2 w-100 d-inline-block px-3" active ClassName="active">
          <i className="fa fa-users me-2"/>
            Certificado</NavLink>
        </li>
      </ul>

    </div>
  )
}

export default Sidebar
